// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .small-bad-button {
  .previousActions {
    width: 90%;
    text-align: left;
    margin: 1em;
    border-radius: 10px;
  }
  background-color: rgb(255, 205, 205);
  border: 1px solid rgb(161, 63, 63);
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  outline: none;
  outline: 0;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  touch-action: manipulation;
}

@media (min-width: 768px) {
  .small-bad-button {
    padding: 5px 15px;
  }
} */

.small-bad-button {
  width: max-content;
  text-align: left;
  margin: 1em;
  border-radius: 10px;
  background-color: rgb(255, 205, 205);
  border: 1px solid rgb(161, 63, 63);
  color: rgb(163, 106, 106);
  border-radius: 7px;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.small-bad-button::before {
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

@media (min-width: 768px) {
  .small-bad-button {
    padding: 5px 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/Buttons/SmallBadButton.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA4BG;;AAEH;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,oCAAoC;EACpC,kCAAkC;EAClC,yBAAyB;EACzB,kBAAkB;EAClB,8CAA8C;AAChD;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* .small-bad-button {\n  .previousActions {\n    width: 90%;\n    text-align: left;\n    margin: 1em;\n    border-radius: 10px;\n  }\n  background-color: rgb(255, 205, 205);\n  border: 1px solid rgb(161, 63, 63);\n  border-radius: 7px;\n  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;\n  box-sizing: border-box;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 300;\n  outline: none;\n  outline: 0;\n  text-align: center;\n  transform: translateY(0);\n  transition: transform 150ms, box-shadow 150ms;\n  user-select: none;\n  touch-action: manipulation;\n}\n\n@media (min-width: 768px) {\n  .small-bad-button {\n    padding: 5px 15px;\n  }\n} */\n\n.small-bad-button {\n  width: max-content;\n  text-align: left;\n  margin: 1em;\n  border-radius: 10px;\n  background-color: rgb(255, 205, 205);\n  border: 1px solid rgb(161, 63, 63);\n  color: rgb(163, 106, 106);\n  border-radius: 7px;\n  transition: all 175ms cubic-bezier(0, 0, 1, 1);\n}\n\n.small-bad-button::before {\n  transition: all 175ms cubic-bezier(0, 0, 1, 1);\n}\n\n@media (min-width: 768px) {\n  .small-bad-button {\n    padding: 5px 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
