import React from "react";

class CodingResult extends React.Component {
    render() {
        if (this.props.directСode) {
            return (
                <div className="my-border">
                    <h2>Результат:</h2>
                    <br />
                    <label>Прямой код</label>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.directСode}</output>

                    <label>Обратный код</label>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.reverseСode}</output>

                    <label>Дополнительный код</label>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.additionalСode}</output>
                </div>
            );
        }
        return (<></>);
    }
}

export default CodingResult;