let DarkSelectTheme =
    (theme) => ({
        ...theme,
        borderWidth: "0px",
        colors: {
            ...theme.colors,
            primary25: '#e8f8ff',
            primary50: '#e8f8ff',
            primary75: '#e8f8ff',
            primary: 'rgb(205, 222, 255)',
        },
    })

export default DarkSelectTheme;