import React from "react";

class ListOfArguments extends React.Component {
    render() {
        if (!this.props.arr || !this.props.arr[0]) {
            return (<></>);
        }
        let args = [];

        for (let i = 0; i < this.props.arr.length; i += 2) {
            args.push([this.props.arr[i], this.props.arr[i + 1]]);
        }

        return (
            <div className="width100">
                <h3>Агрументы:</h3>
                {
                    args.map((i) => {
                        return (
                            <div key={i[0]} className="tableRow">
                                <h3 className="mid tableCell">{i[0]}:{'\u00A0'}</h3>
                                <input className="form_control argumentem tableCell" autoComplete="off" type="text" value={i[1]} onChange={(e) => this.props.onChange(null, [i[0], e.target.value])} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default ListOfArguments;