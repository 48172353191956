import React from "react";
import Select from "react-select";
import DarkSelect from './Styles/DarkSelectStyle';
import LightSelect from './Styles/LightSelectStyle';
import DarkSelectTheme from "./Themes/DarkSelectTheme";
import LightSelectTheme from "./Themes/LightSelectTheme";
import Cookies from 'js-cookie';

class SelectBlock extends React.Component {
    render() {
        let select;

        if (Cookies.get("themeColor") === "dark") {
            select = <Select noOptionsMessage={() => "Пусто"} theme={DarkSelectTheme} onChange={(e) => { this.props.onChange(e) }} value={this.props.value} styles={DarkSelect} isSearchable options={this.props.options} />;
        } else {
            select = <Select noOptionsMessage={() => "Пусто"} theme={LightSelectTheme} onChange={(e) => { this.props.onChange(e) }} value={this.props.value} styles={LightSelect} isSearchable options={this.props.options} />;
        }

        return (
            select
        );
    }
}

export default SelectBlock;