// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #222831;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.clear-button::before {
  position: absolute;
  top: -20px;
  content: "Очистить форму";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

@media (min-width: 768px) {
  .clear-button:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: rgb(255, 69, 69);
    align-items: center;
  }

  .clear-button:hover .svgIcon {
    width: 50px;
    transition-duration: 0.3s;
    transform: translateY(60%);
  }

  .clear-button:hover::before {
    font-size: 13px;
    opacity: 1;
    transform: translateY(30px);
    transition-duration: 0.3s;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/Buttons/ClearingButton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,6CAA6C;EAC7C,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,kCAAkC;IAClC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,0BAA0B;EAC5B;;EAEA;IACE,eAAe;IACf,UAAU;IACV,2BAA2B;IAC3B,yBAAyB;EAC3B;AACF","sourcesContent":[".clear-button {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  background-color: #222831;\n  border: none;\n  font-weight: 600;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);\n  cursor: pointer;\n  transition-duration: 0.3s;\n  overflow: hidden;\n  position: relative;\n}\n\n.svgIcon {\n  width: 12px;\n  transition-duration: 0.3s;\n}\n\n.svgIcon path {\n  fill: white;\n}\n\n.clear-button::before {\n  position: absolute;\n  top: -20px;\n  content: \"Очистить форму\";\n  color: white;\n  transition-duration: 0.3s;\n  font-size: 2px;\n}\n\n@media (min-width: 768px) {\n  .clear-button:hover {\n    width: 140px;\n    border-radius: 50px;\n    transition-duration: 0.3s;\n    background-color: rgb(255, 69, 69);\n    align-items: center;\n  }\n\n  .clear-button:hover .svgIcon {\n    width: 50px;\n    transition-duration: 0.3s;\n    transform: translateY(60%);\n  }\n\n  .clear-button:hover::before {\n    font-size: 13px;\n    opacity: 1;\n    transform: translateY(30px);\n    transition-duration: 0.3s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
