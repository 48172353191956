import React from "react";
import Footer from "../../components/GeneralItems/Footer";
import Header from "../../components/GeneralItems/Header";
import BugReport from "../../components/BugReport/BugReport";
import HeadData from "../../components/HeadData/HeadData";

class PageContacts extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <HeadData
                    title="Контакты – MathInstruments"
                    url="https://MathInstruments.ru/Contacts"
                    keys="MathInstruments, Main, MaIn, математические калькуляторы, калькуляторы, математические инструменты, Контакты, контакты, Информация, информация, инфо, связь"
                    description="Контакты, необходимые сведения, чтобы связаться с нами, форма для обращений, сообщение об ошибках."
                />
                <Header updater={() => this.forceUpdate()} />
                
                <main className="content">
                    <div id="input" className="form">
                        <center><h1>Контакты</h1></center>
                        <span className="discription">{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}На этой странице вы найдете все необходимые сведения, чтобы связаться с нами. Мы всегда рады обратной связи и готовы ответить на любые ваши вопросы.<br /><br />{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}Если у вас есть предложение о сотрудничестве, или вы обнаружили ошибку в работе сервиса, то вы можете написать нам на почту:{'\u00A0'} <a id="report-begin" href="mailto:order@mathinstruments@mail.ru">mathinstruments@mail.ru</a> {'\u00A0'}или обратиться к нам с помощью следующей формы:<BugReport />Основатели сайта: <a href="https://t.me/Vikt0r04"> {'\u00A0'}Швец В.В.</a>{'\u00A0'}{'\u00A0'}<a href="https://t.me/C4HK1">Поваров А.А.</a></span>
                    </div>
                </main>

                <Footer />
            </div>
        );
    }
}

export default PageContacts;