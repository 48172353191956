import React from "react";
import emailjs from "@emailjs/browser"

class BugReport extends React.Component {
    async sentMsg(body) {
        document.getElementById("report").value = "";
        emailjs.init("NtriT3_8WpPfn0MR1");
        emailjs.send("service_70apx0r", "template_39h3x5m", { message: body });
    }

    showAlert() {
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    render() {
        return (
            <>
                <textarea className="bug-report my-border" id="report" placeholder="Введите текст обращения"></textarea>
                <div className="bug-buttons">
                    <button className="bug-sent-button previousAction" type="button" onClick={() => { this.showAlert(); this.sentMsg(document.getElementById("report").value) }}>Отправить</button>
                    <button className="bug-clear-button previousAction" type="button" onClick={() => { document.getElementById("report").value = "" }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>
                </div>
                <br />
            </>
        )
    }
}

export default BugReport;