import React from "react";

class LogicResult extends React.Component {
    render() {
        if (this.props.result && this.props.operation !== 4) {
            if (this.props.operation === 1) {
                return (
                    <div className="my-border">
                        <h4>Полином Жегалкина:</h4>
                        <output className="form_control output-style" onChange={() => { }}>{this.props.result}</output>
                    </div>
                );
            } else if (this.props.operation === 2) {
                return (
                    <div className="my-border">
                        <h4>СКНФ:</h4>
                        <output className="form_control output-style" onChange={() => { }}>{this.props.result}</output>
                    </div>
                );
            }
            return (
                <div className="my-border">
                    <h4>СДНФ:</h4>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.result}</output>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }
}

export default LogicResult;