import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/Site.css"
import "./css/General/Header.css"
import "./css/General/Footer.css"
import "./css/ThemeSwitcher/ThemeSwitcher.css"
import "./css/Buttons/BigBadButton.css"
import "./css/Buttons/BigGoodButton.css"
import "./css/Buttons/ClearingButton.css"
import "./css/Buttons/SmallBadButton.css"
import "./css/Themes/LightTheme.css"
import "./css/Themes/DarkTheme.css"
import "./css/Slider/Slider.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);