import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import ListOfNumbers from "../../components/NodNok/ListOfNumbers.js";
import NokNodResult from "../../components/Results/NokNodResult.js";
import HeadData from "../../components/HeadData/HeadData";

const NOK_NOD_REST_URL = "https://math-instruments.ru:8443/NokNod";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class PageNokNod extends React.Component {
    normalizeString(str) {
        while (str[0] === " ") {
            str = str.slice(1);
        }
        while (str[str.length - 1] === " ") {
            str = str.slice(0, str.length - 1);
        }
        return str;
    }

    constructor(props) {
        super(props);

        this.state = {
            button: 1,
            numbersCount: "",
            numbers: [],
            NOK: "",
            NOD: "",
            sentButtonColor: "big-bad-button",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(NOK_NOD_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                let numbers = [];
                let numbersCount = "";
                if (response.data.numbersCount) {
                    numbers = response.data.numbers;
                    numbersCount = response.data.numbersCount;
                    this.setState({ numbers: response.data.numbers });
                    this.setState({ numbersCount: response.data.numbersCount });
                } else {
                    this.setState({ numbers: [] });
                    this.setState({ numbersCount: "" });
                }
                this.setState({ NOK: response.data.nok });
                this.setState({ NOD: response.data.nod });

                this.handleInputChange(numbersCount, numbers);
            } else {
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if (this.state.sentButtonColor === "big-bad-button" && !(this.state.button === 2)) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        var formData = new FormData();
        formData.append('numbers', this.normalizeString(this.state.numbers));
        formData.append('numbersCount', this.normalizeString(this.state.numbers.length));
        formData.append('button', this.state.button);

        axios.post(NOK_NOD_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                let numbers = [];
                let numbersCount = "";
                if (response.data.numbersCount) {
                    numbers = response.data.numbers;
                    numbersCount = response.data.numbersCount;
                    this.setState({ numbers: response.data.numbers });
                    this.setState({ numbersCount: response.data.numbersCount });
                } else {
                    this.setState({ numbers: [] });
                    this.setState({ numbersCount: "" });
                }

                this.setState({ NOK: response.data.nok });
                this.setState({ NOD: response.data.nod });

                this.handleInputChange(numbersCount, numbers);
            } else {
                this.setState({ NOD: "Введённое число содержит некорректные символы." });
                this.setState({ NOK: "Введённое число содержит некорректные символы." });
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })

        if (this.state.button !== 2) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }
    }

    setPreviousFormStates(e) {
        this.setState({ numbers: e.numbers });
        this.setState({ numbersCount: e.numbers.length });
        this.setState({ NOD: e.NOD });
        this.setState({ NOK: e.NOK });

        this.setState({ sentButtonColor: "big-good-button" });

        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    isNumber(str) {
        if (str.length === 0) {
            return false;
        }

        if (str[0] === '+') {
            if (str.length === 1) {
                return false;
            }
            str = str.slice(1);
        }

        let digits = "01234567890";

        let flag = true;

        for (let i = 0; i < str.length; ++i) {
            if (digits.indexOf(str[i]) === -1) {
                flag = false;
            }
        }

        return flag;
    }

    handleInputChange(numbersCount, remNumbers) {
        let flag = true;

        if (numbersCount !== null) {
            this.setState({ numbersCount: numbersCount });
        } else {
            numbersCount = this.state.numbersCount;
        }

        let numbers = [];

        for (let i = 0; i < Math.min(numbersCount, 50); ++i) {
            if (document.getElementById(i + "")) {
                numbers.push(document.getElementById(i + "").value);
            } else {
                if (remNumbers[i]) {
                    numbers.push(remNumbers[i]);
                } else {
                    numbers.push("");
                }
            }
        }

        this.setState({ numbers: numbers });

        for (let i = 0; i < numbers.length; ++i) {
            if (!this.isNumber(numbers[i])) {
                flag = false;
            }
        }

        flag = flag && this.isNumber(numbersCount);

        if (flag && numbers.length > 1) {
            this.setState({ sentButtonColor: "big-good-button" });
        } else {
            this.setState({ sentButtonColor: "big-bad-button" });
        }
    }

    render() {
        return (
            <div className="wrapper">
                <HeadData
                    title="Нахождение НОК и НОД набора чисел – MathInstruments"
                    url="https://MathInstruments.ru/NokNod"
                    keys="MathInstruments, Main, MaIn, НОК, НОД, наибольший общий делитель, наименьшее общее кратное, поиск НОК НОД онлайн, НОК НОК онлайн, математические калькуляторы, онлайн, калькуляторы, математические инструменты"
                    description="На данной странице пользователи могут без затруднений получить наибольший общий делитель и наименьшее общее кратное набора чисел произвольной длины."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form">
                        <h2>Нахождение наибольшего общего делителя и наименьшего общего кратного.</h2>

                        <form onSubmit={this.handleSubmit}>
                            <center className="my-border">
                                <h3>Количество чисел:</h3>
                                <input className="form_control em20" type="text" placeholder="Введите количество чисел" value={this.state.numbersCount} onChange={(e) => { this.handleInputChange(e.target.value, this.state.numbers); }} />

                                <ListOfNumbers numbers={this.state.numbers} numbersCount={this.state.numbersCount} onChange={() => { this.handleInputChange(null) }} />

                                <button id="result" className={this.state.sentButtonColor} type="submit" onClick={() => { this.setState({ button: 1 }) }}>{'\u00A0'} Вычислить {'\u00A0'}</button>
                            </center>

                            <center>
                                <NokNodResult NOK={this.state.NOK} NOD={this.state.NOD} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => { this.setState({ button: 2 }); }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates} elementName="NokNod" /></center>
                        </form>
                    </center>

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по получению наибольшего общего делителя и наименьшего общего кратного чисел:</h3></center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям предоставлен функционал, позволяющий вычислять наибольший общий делитель и наименьшее общее кратное произвольного количества чисел(не превышающего 50). Все числа должны быть натуральными. Однако в поле "Количество чисел" может быть указано только число, строго больше единицы. Чтобы получить результат необходимо в соответствующее поле указать количество чисел, используемых при подсчёте НОК и НОД, после чего указать все числа и нажать на кнопку "Вычислить".</p>
                    </div>
                </main >

                <Footer />
            </div >
        );
    }
}

export default PageNokNod;
