import React from "react";

class Matrix extends React.Component {
    render() {
        var line = 0;
        let className = "matrix-form";

        return (
            <div className={className}>
                <table className='two_matrix matrix'>
                    <tbody>
                        {this.props.matrix.map((i) => {
                            var column = 0;
                            return (<tr key={line++}>
                                {
                                    i.map((j) => {
                                        var str = this.props.matrixId + line + column;
                                        if (document.getElementById(str)) {
                                            document.getElementById(str).value = j;
                                        }
                                        return (
                                            <td key={'1' + column++} className='matrix-element-background'>
                                                <input size={3} className='form_control em35 no-padding' id={str} defaultValue={j} onChange={() => this.props.onChange()}></input>
                                            </td>
                                        );
                                    })
                                }
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div >
        )
    }
}

export default Matrix;