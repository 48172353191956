import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import Result from "../../components/Results/Result.js";
import PowerInputText from "../../components/Power/PowerInputText.js";
import Slider from "../../components/Slider/Slider.js";
import HeadData from "../../components/HeadData/HeadData";

const POW_REST_URL = "https://math-instruments.ru:8443/Pow";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class PagePow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            additionalStyle: "",
            cursor: -1,
            button: 1,
            input: "",
            normalizeInput: "",
            precision: 0,
            result: "",
            sentButtonColor: "big-bad-button",
        }

        document.onclick = (e) => { this.handleClickDocument(e) };
        document.onkeydown = (e) => { if (e.key === "Shift") { this.flag = true } };
        document.onkeyup = (e) => { if (e.key === "Shift") { this.flag = false } };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleButtonColor = this.handleButtonColor.bind(this);
        this.isCorrectInput = this.isCorrectInput.bind(this);
        this.handleClickDocument = this.handleClickDocument.bind(this);
        this.backSpace = this.backSpace.bind(this);
        this.moveCursorLeft = this.moveCursorLeft.bind(this);
        this.moveCursorRight = this.moveCursorRight.bind(this);
        this.addOps = this.addOps.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
        this.changeCursorPosition = this.changeCursorPosition.bind(this);
    }

    handleClickDocument(e) {
        if (window.location.href !== "https://math-instruments.ru/Pow") {
            return;
        }

        let obj = e.srcElement;

        if (obj.id === "main-input" || obj.id === "scroll-section") {
            this.handleActivate(e);
        }
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(POW_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ input: response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)) });
                this.setState({ normalizeInput: response.data.normalizeInput.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)) });
                this.setState({ precision: response.data.precision });
                this.setState({ result: response.data.result });
                this.handleInputChange(response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)));
                this.handleButtonColor(response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)));
                this.setState({ cursor: response.data.input.length + 1 });
            } else {
                this.setState({ sentButtonColor: "big-bad-button" });
            }

            this.forceUpdate();
        })
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if (this.state.sentButtonColor === "big-bad-button" && !(this.state.button === 2)) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        var formData = new FormData();
        formData.append('input', this.state.input.replaceAll(String.fromCharCode(1), '`').replaceAll(String.fromCharCode(2), '~'));
        formData.append('normalizeInput', this.state.normalizeInput.replaceAll(String.fromCharCode(1), '`').replaceAll(String.fromCharCode(2), '~'));
        formData.append('precision', this.state.precision);
        formData.append('button', this.state.button);

        axios.post(POW_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ input: response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)) });
                this.setState({ normalizeInput: response.data.normalizeInput.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)) });
                this.setState({ precision: response.data.precision });
                this.setState({ result: response.data.result });
                if (this.state.button === 2) {
                    document.getElementById("hidden-input").value = "";
                }
                this.handleButtonColor(response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)));
                this.setState({ cursor: response.data.input.replaceAll('`', String.fromCharCode(1)).replaceAll('~', String.fromCharCode(2)).length + 1 });
            } else {
                this.setState({ result: "Введённое число содержит некорректные символы." });
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })

        if (this.state.button !== 2) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }

        this.forceUpdate();
    }

    setPreviousFormStates(e) {
        this.setState({ input: e.input });
        this.setState({ normalizeInput: e.normalizeInput });
        this.setState({ precision: e.precision });
        this.setState({ result: e.result });
        this.setState({ cursor: e.input.length + 1 });

        this.setState({ sentButtonColor: "big-good-button" });

        document.getElementById("hidden-input").value = e.input;
        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    isNumber(str) {
        if (str.length === 0) {
            return false;
        }

        if (str[0] === '+' || str[0] === '-') {
            str = str.slice(1);
        }

        let digits = "01234567890";

        let flag = true;

        for (let i = 0; i < str.length; ++i) {
            if (digits.indexOf(str[i]) === -1) {
                flag = false;
            } else if (str[i] === '.') {
                if (i === 0 || i === (str.length - 1) || (str.split(".").length - 1) > 1) {
                    flag = false;
                }
            }
        }

        return flag;
    }

    getSelectionStart(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveEnd('character', o.value.length)
            if (r.text === '') return o.value.length
            return o.value.lastIndexOf(r.text)
        } else return o.selectionStart
    }

    getSelectionEnd(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveStart('character', -o.value.length)
            return r.text.length
        } else return o.selectionEnd
    }

    handleActivate(e) {
        if ((e.clientX - e.target.getBoundingClientRect().x) < (e.target.getBoundingClientRect().width / 2)) {
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(0, 0);
            this.setState({ cursor: -1 });
        } else {
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(this.state.input.length, this.state.input.length);
            this.setState({ cursor: this.state.input.length - 1 });
        }

        this.setState({ additionalStyle: "active-input" });
        this.forceUpdate();
    }

    findBaseStart(str, id) {
        let cntPowerBorders = 0;
        let opsCnt = -1;
        let base = false;

        for (let i = id; i >= 0; --i) {
            if (!base) {
                if (str[i].charCodeAt(0) === 2) {
                    ++cntPowerBorders;
                } else if (str[i].charCodeAt(0) === 1) {
                    --cntPowerBorders;
                }
                if (cntPowerBorders === 0) {
                    base = true;
                    --i;
                }
            } else {
                if (str[i] === "+" || str[i] === "-" || str[i] === "*" || str[i] === "/" || str[i] === "(" || str[i].charCodeAt(0) === 1) {
                    opsCnt++;
                } else if (str[i] === ")" || str[i].charCodeAt(0) === 2) {
                    opsCnt--;
                }

                if (opsCnt >= 0) {
                    return i + 1;
                }
            }
        }

        return 0;
    }

    findNextOp(str, id) {
        let cnt = 1;

        for (let i = id; i < str.length; ++i) {
            if (str[i] === "+" || str[i] === "-" || str[i] === "*" || str[i] === "/" || str[i] === ")") {
                --cnt;
            } else if (str[i] === "(") {
                ++cnt;
            }

            if (cnt === 0) {
                return i;
            }
        }

        return str.length;
    }

    handleInputChange(str) {
        let elem = document.getElementById("hidden-input");
        let s = this.getSelectionStart(elem) - 1, e = this.getSelectionEnd(elem);
        let first = true;

        if (str.length < this.state.input.length) {
            if (this.state.cursor > s) {
                if (this.state.input[this.state.cursor].charCodeAt(0) === 2) {
                    document.getElementById("hidden-input").value = this.state.input;
                    document.getElementById("hidden-input").focus();
                    document.getElementById("hidden-input").setSelectionRange(s + 1, s + 1);

                    this.setState({ cursor: s });

                    this.forceUpdate();
                    return;
                } else if (str[s] === "^") {
                    let res = "";

                    for (let i = 0; i < str.length; ++i) {
                        if (str[i].charCodeAt(0) === 2 && i > s && first) {
                            first = false;
                            continue;
                        }
                        if (i !== s) {
                            res += str[i];
                        }
                    }

                    this.setState({ input: res });
                    document.getElementById("hidden-input").value = res;
                    document.getElementById("hidden-input").focus();
                    document.getElementById("hidden-input").setSelectionRange(s, s);

                    this.setState({ cursor: s - 1 });

                    this.forceUpdate();
                    return;
                }
            } else {
                if (this.state.input[this.state.cursor + 1].charCodeAt(0) === 2) {
                    document.getElementById("hidden-input").value = this.state.input;
                    document.getElementById("hidden-input").focus();
                    document.getElementById("hidden-input").setSelectionRange(e + 1, e + 1);

                    this.setState({ cursor: e });

                    this.forceUpdate();
                    return;
                } else if (this.state.input[this.state.cursor + 1] === '^') {
                    let res = "";
                    let cnt = 1;

                    for (let i = 0; i < this.state.input.length; ++i) {
                        if (i > (this.state.cursor + 2) && this.state.input[i].charCodeAt(0) === 2 && cnt !== 0) {
                            if (this.state.input[i].charCodeAt(0) === 1) {
                                ++cnt;
                            } else if (this.state.input[i].charCodeAt(0) === 2) {
                                if (--cnt === 0) {
                                    continue;
                                }
                            }
                        }
                        if (i !== (this.state.cursor + 1) && i !== (this.state.cursor + 2)) {
                            res += this.state.input[i];
                        }
                    }

                    this.setState({ input: res });
                    document.getElementById("hidden-input").value = res;
                    document.getElementById("hidden-input").focus();
                    document.getElementById("hidden-input").setSelectionRange(s + 1, s + 1);

                    this.setState({ cursor: s });

                    this.forceUpdate();
                    return;
                }
            }
        }

        if (str[s] === '^') {
            if (s === 0 || (str[s - 1].charCodeAt(0)) !== 2) {
                str = str.slice(0, s + 1) + String.fromCharCode(1) + str.slice(e, this.findNextOp(str, e)) + String.fromCharCode(2) + str.slice(this.findNextOp(str, e));
                ++s;
            } else {
                str = str.slice(0, this.findBaseStart(str, s - 1)) + "(" + str.slice(this.findBaseStart(str, s - 1), s) + ")^" + String.fromCharCode(1) + str.slice(e, this.findNextOp(str, e)) + String.fromCharCode(2) + str.slice(this.findNextOp(str, e));
                s += 3;
            }
        }

        this.setState({ input: str });
        document.getElementById("hidden-input").value = str;
        document.getElementById("hidden-input").focus();
        document.getElementById("hidden-input").setSelectionRange(s + 1, s + 1);

        this.setState({ cursor: s });
    }

    flag = false;

    changeCursorPosition(event) {
        let elem = document.getElementById("hidden-input");
        let s = this.getSelectionStart(elem) - 1, e = this.getSelectionEnd(elem);
        if (event.key === "ArrowLeft") {
            if (this.flag) {
                event.preventDefault();
            }
            if (s > 0 && elem.value[s].charCodeAt(0) === 1) {
                this.setState({ cursor: s - 2 });
                document.getElementById("hidden-input").focus();
                document.getElementById("hidden-input").setSelectionRange(s, s);
            } else {
                if (s < 0) {
                    ++s;
                }
                this.setState({ cursor: s - 1 });
            }
        } else if (event.key === "ArrowRight") {
            if (this.flag) {
                event.preventDefault();
            }
            if (elem.value[e] === '^') {
                this.setState({ cursor: e + 1 });
                document.getElementById("hidden-input").focus();
                document.getElementById("hidden-input").setSelectionRange(e + 1, e + 1);
                return;
            }

            if (e === elem.value.length) {
                e = elem.value.length - 1;
            }
            this.setState({ cursor: e });
        }
    }

    addOps(op) {
        this.setState({ additionalStyle: "active-input" });

        let elem = document.getElementById("hidden-input");
        let s = this.getSelectionStart(elem), e = this.getSelectionEnd(elem);
        let str = elem.value;
        if (op !== "^") {
            str = str.slice(0, s) + op + str.slice(e);
        } else {
            if (s === 0 || (str[s - 1].charCodeAt(0)) !== 2) {
                str = str.slice(0, s) + "^" + String.fromCharCode(1) + str.slice(e, this.findNextOp(str, e)) + String.fromCharCode(2) + str.slice(this.findNextOp(str, e));
                ++s;
            } else {
                str = str.slice(0, this.findBaseStart(str, s - 1)) + "(" + str.slice(this.findBaseStart(str, s - 1), s) + ")^" + String.fromCharCode(1) + str.slice(e, this.findNextOp(str, e)) + String.fromCharCode(2) + str.slice(this.findNextOp(str, e));
                s += 3;
            }
        }

        this.handleInputChange(str, null);

        elem.focus();
        elem.setSelectionRange(s + 1, s + 1);
        this.setState({ cursor: s });
        this.forceUpdate();
    }

    moveCursorLeft() {
        this.setState({ additionalStyle: "active-input" });
        let elem = document.getElementById("hidden-input");
        let s = this.getSelectionStart(elem) - 1;

        if (s === -1) {
            ++s;
        }

        if (s > 0 && elem.value[s].charCodeAt(0) === 1) {
            this.setState({ cursor: s - 2 });
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(s - 1, s - 1);
            return
        }

        document.getElementById("hidden-input").focus();
        document.getElementById("hidden-input").setSelectionRange(s, s);
        this.setState({ cursor: s - 1 });
    }

    moveCursorRight() {
        this.setState({ additionalStyle: "active-input" });
        let elem = document.getElementById("hidden-input");
        let e = this.getSelectionEnd(elem);

        if (elem.value[e] === '^') {
            this.setState({ cursor: e + 1 });
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(e + 2, e + 2);
            return;
        }

        document.getElementById("hidden-input").focus();
        document.getElementById("hidden-input").setSelectionRange(e + 1, e + 1);
        if (e === this.state.input.length) {
            --e;
        }

        this.setState({ cursor: e });
    }

    backSpace() {
        this.setState({ additionalStyle: "active-input" });

        let first = true;

        let elem = document.getElementById("hidden-input");
        let s = this.getSelectionStart(elem) - 1;

        let str = elem.value;

        let res = "";

        if (s < 0) {
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(0, 0);
            this.setState({ cursor: -1 });
            return;
        }

        if (str[s].charCodeAt(0) === 2) {
            document.getElementById("hidden-input").value = this.state.input;
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(s, s);

            this.setState({ cursor: s - 1 });

            this.forceUpdate();
            return;
        } else if (str[s - 1] === "^") {
            for (let i = 0; i < str.length; ++i) {
                if (str[i].charCodeAt(0) === 2 && i > s && first) {
                    first = false;
                    continue;
                }
                if (i !== s && i !== (s - 1)) {
                    res += str[i];
                }
            }

            this.setState({ input: res });
            document.getElementById("hidden-input").value = res;
            document.getElementById("hidden-input").focus();
            document.getElementById("hidden-input").setSelectionRange(s - 1, s - 1);

            this.setState({ cursor: s - 2 });

            this.forceUpdate();
            return;
        }

        for (let i = 0; i < str.length; ++i) {
            if (i !== s) {
                res += str[i];
            }
        }

        this.setState({ input: res });
        this.setState({ cursor: s - 1 });

        document.getElementById("hidden-input").value = res;
        document.getElementById("hidden-input").focus();
        document.getElementById("hidden-input").setSelectionRange(s, s);
    }

    findIdByPos(pos, str, position) {
        let positionInLevel = -1;
        let pow = false;
        let power = "";
        let bracketsCount = 0;
        let rem = 0;

        for (let i = 0; i < str.length; ++i) {
            if (!pow) {
                if (str[i] !== '^') {
                    positionInLevel++;
                    if ((position + positionInLevel) === pos) {
                        return { cursor: i, backShift: 1 };
                    }
                } else {
                    positionInLevel++;
                    rem = i;
                    pow = true;
                    bracketsCount = 0;
                    power = "";
                }
            } else {
                power += str[i];

                if (str[i].charCodeAt(0) === 1) {
                    bracketsCount++;
                } else if (str[i].charCodeAt(0) === 2) {
                    bracketsCount--;
                }

                if (!bracketsCount) {
                    pow = false;
                    if (pos === (position + positionInLevel + " ")) {
                        return { cursor: rem + power.length - 1, backShift: power.length - 2 };
                    }
                    let tmp = this.findIdByPos(pos, power.slice(1, power.length - 1), position + positionInLevel + " ");
                    if (tmp !== null) {
                        return { cursor: rem + 2 + tmp.cursor, backShift: 1 };
                    }
                }
            }
        }

        return null;
    }

    setCursor(e) {
        this.setState({ additionalStyle: "active-input" });
        let cursorAndBackShift = this.findIdByPos(e.target.id, document.getElementById("hidden-input").value, "");
        if (cursorAndBackShift === null || cursorAndBackShift === undefined) {
            cursorAndBackShift = { cursor: -1, backShift: 0 }
        }
        let cursor = cursorAndBackShift.cursor;

        if ((e.clientX - e.target.getBoundingClientRect().x) < (e.target.getBoundingClientRect().width / 2)) {
            cursor -= cursorAndBackShift.backShift;
        }
        this.setState({ cursor: cursor });
        document.getElementById("hidden-input").focus();
        document.getElementById("hidden-input").setSelectionRange(cursor + 1, cursor + 1);
        this.forceUpdate();
    }

    isDigit(c) {
        return typeof c === 'string' && c.length === 1 && c >= '0' && c <= '9';
    }

    insert(str, index, string) {
        if (index > 0) {
            return str.substring(0, index) + string + str.substring(index, str.length);
        }

        return string + str;
    };

    isCorrectInput(str) {
        let level = "";
        let pow = false;
        let power = "";
        let bracketsCount = 0;

        for (let i = 0; i < str.length; ++i) {
            if (!pow) {
                if (str[i] !== "^") {
                    level += str[i];
                } else {
                    pow = true;
                    bracketsCount = 0;
                    power = "";
                }
            } else {
                power += str[i];

                if (str[i].charCodeAt(0) === 1) {
                    bracketsCount++;
                } else if (str[i].charCodeAt(0) === 2) {
                    bracketsCount--;
                }

                if (!bracketsCount) {
                    pow = false;

                    if (str[i + 1]) {
                        if (!(str[i + 1].charCodeAt(0) === 2 || str[i + 1] === "+" || str[i + 1] === "-" || str[i + 1] === "*" || str[i + 1] === "/" || str[i + 1] === "(" || str[i + 1] === ")")) {
                            level += "*";
                        }
                    }

                    if (!this.isCorrectInput(power.slice(1, power.length - 1))) {
                        return false;
                    }
                }
            }
        }

        let operations = "+-*/";

        str = level.replaceAll(" ", '').replaceAll(",", ".");

        if (str.indexOf("()") !== -1 || str.length === 0) {
            return false;
        }

        str = "(" + str + ")";
        let brackets = "";

        for (let i = 1; i < (str.length - 1); ++i) {
            if (str[i] === '(' || str[i] === ')') {
                brackets += str[i];
            }
        }

        while (brackets.indexOf("()") !== -1) {
            brackets = brackets.replaceAll("()", "");
        }

        if (brackets.length !== 0) {
            return false;
        }

        let argument = "";

        for (let i = 1; i < str.length; ++i) {
            if (str[i] === '(') {
                if (argument.length) {
                    str = this.insert(str, i, "*");
                } else if (str[i - 1] === ')') {
                    str = this.insert(str, i, "*");
                }

                argument = "";
            } else if (str[i] === ')') {
                if (!argument.length && str[i - 1] !== ')') {
                    return false;
                }

                argument = "";
            } else if (this.isDigit(str[i])) {
                argument += str[i];
            } else if (operations.indexOf(str[i]) !== -1) {
                if (!argument.length && str[i - 1] !== ')' && operations.indexOf(str[i]) > 1) {
                    return false;
                }

                argument = "";
            } else if (str[i] === ".") {
                if (!argument.length || str[i + 1] === ")" || argument.indexOf('.') !== -1 || str[i + 1] === "(" || str[i + 1] === "^" || str[i + 1].charCodeAt(0) === 2) {
                    return false;
                }
                argument += str[i];
            } else {
                return false;
            }
        }

        return true;
    }

    handleButtonColor(str) {
        if (this.isCorrectInput(str)) {
            this.setState({ sentButtonColor: "big-good-button" });
            str = str.replaceAll(" ", '');
            while (str.indexOf("++") !== -1 || str.indexOf("-+") !== -1 || str.indexOf("--") !== -1 || str.indexOf("++") !== -1) {
                str = str.replaceAll("++", "+");
                str = str.replaceAll("-+", "-");
                str = str.replaceAll("+-", "-");
                str = str.replaceAll("--", "-");
            }

            for (let i = 0; i < str.length; ++i) {
                if (str[i] === "(" && i > 0) {
                    if (!(str[i - 1] === "+" || str[i - 1] === "-" || str[i - 1] === "*" || str[i - 1] === "/" || str[i - 1] === "(" || str[i - 1].charCodeAt(0) === 1)) {
                        str = this.insert(str, i, "*");
                        ++i;
                    }
                } else if ((str[i] === ")" || str[i].charCodeAt(0) === 2) && i < (str.length - 1)) {
                    if (!(str[i + 1] === "+" || str[i + 1] === "-" || str[i + 1] === "*" || str[i + 1] === "/" || str[i + 1] === ")" || str[i + 1].charCodeAt(0) === 2 || str[i + 1] === "^")) {
                        str = this.insert(str, i + 1, "*");
                        ++i;
                    }
                }
            }

            this.setState({ normalizeInput: str });
        } else {
            this.setState({ sentButtonColor: "big-bad-button" });
            this.setState({ normalizeInput: "" });
        }
    }

    render() {
        return (
            <div className="wrapper">
                <HeadData
                    title="Калькулятор любых степеней – MathInstruments"
                    url="https://MathInstruments.ru/Pow"
                    keys="MathInstruments, Main, MaIn, вещественная степень числа, степень числа онлайн, степень числа, калькулятор степеней, онлайн калькулятор, онлайн, вещественные степени, онлайн, математические калькуляторы, калькуляторы, математические инструменты"
                    description="На данной странице мы предлагаем пользователям функционал, позволяющий легко получить значение выражения, состоящего из чисел в различных степенях."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form">
                        <h2>Вычисление выражений с различными степенями.</h2>
                        {/* <output id="cursor-position"></output> */}
                        <form onSubmit={this.handleSubmit}>
                            <center className="my-border">
                                <h3>Выражение:</h3>
                                <div id="main-input" className={"form_control em315 power-input " + this.state.additionalStyle}>
                                    <input onKeyDown={(e) => { this.changeCursorPosition(e) }} onBlur={() => { this.setState({ cursor: document.getElementById("hidden-input").value.length }); this.setState({ additionalStyle: "" }); }} id="hidden-input" className="hidden-input" onChange={(e) => { this.handleInputChange(e.target.value); this.handleButtonColor(e.target.value); }}></input>

                                    <PowerInputText inputField={document.getElementById("hidden-input")} cursor={this.state.cursor} setCursor={(e) => { this.setCursor(e) }} />
                                </div >
                                <br />
                                <div className="logic-operations-border">
                                    <h3>Операции:</h3>
                                    <div className="mr1">
                                        <button className="previousAction powOps" onClick={() => { this.addOps("^") }} type="button"><svg viewBox="0 0 30 30" width="30" height="30"><path d="M18.75 10v10H8.75V10zm1.25 -1.25H7.5v12.5h12.5V13.75zm3.125 -4.375v1.25h-1.25V4.375zm1.25 -1.25h-3.75v3.75h3.75z" className="path-ops" /></svg></button>
                                        <button className="previousAction powOps" onClick={() => { this.moveCursorLeft() }} type="button">
                                            <svg width="18" height="19.2" className="path-ops cors-line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19.2"><title /><g><title>Layer 1</title><g id="Layer_2" data-name="Layer 2"><path id="svg_1" d="M7.8 15.6a0.6 0.6 0 0 1 -0.426 -0.174l-5.4 -5.4a0.6 0.6 0 0 1 0 -0.852l5.4 -5.4a0.6 0.6 0 1 1 0.852 0.852L3.246 9.6l4.98 4.974a0.6 0.6 0 0 1 0 0.852 0.6 0.6 0 0 1 -0.426 0.174" /><path id="svg_2" d="M16.8 10.2H2.4a0.6 0.6 0 0 1 0 -1.2h14.4a0.6 0.6 0 0 1 0 1.2" /></g><g id="frame"><path id="svg_3" fill="none" width="32" height="32" className="cls-1" d="M0 0h19.2v19.2H0z" /></g></g></svg>
                                        </button>

                                        <button className="previousAction powOps" onClick={() => { this.moveCursorRight() }} type="button">
                                            <svg width="18" height="19.2" className="path-ops cors-line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19.2"><title /><g><title>Layer 1</title><g id="Layer_2" data-name="Layer 2"><path id="svg_1" d="M11.4 15.6a0.605 0.605 0 0 1 -0.426 -0.174 0.6 0.6 0 0 1 0 -0.852l4.98 -4.974 -4.98 -4.974a0.6 0.6 0 0 1 0.852 -0.852l5.4 5.4a0.6 0.6 0 0 1 0 0.852l-5.4 5.4a0.605 0.605 0 0 1 -0.425 0.173" /><path id="svg_2" d="M16.8 10.2h-14.4a0.6 0.6 0 0 1 0 -1.2h14.4a0.6 0.6 0 0 1 0 1.2" /></g><g id="frame"><path id="svg_3" fill="none" width="32" height="32" className="cls-1" d="M0 0h19.2v19.2H0z" /></g></g></svg>
                                        </button>
                                        <button className="previousAction powOps" onClick={() => { this.backSpace() }} type="button"><svg className="path-ops" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.588 73.588"><g id="SVGRepo_bgCarrier" strokeWidth="0" /><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" /><g id="SVGRepo_iconCarrier"><g><g><g><path d="M71.988 11.986H24.548c-0.432 0 -0.832 0.176 -1.136 0.48L0.468 35.666a1.593 1.593 0 0 0 0 2.256L23.428 61.122c0.304 0.304 0.704 0.48 1.136 0.48h47.424c0.88 0 1.6 -0.72 1.6 -1.6V13.586c0 -0.88 -0.72 -1.6 -1.6 -1.6zM70.388 58.386H25.204l-21.36 -21.6 21.376 -21.6h45.168v43.2z" className="path-ops" /><path d="m37.268 27.506 9.264 9.264 -9.264 9.264c-0.624 0.64 -0.608 1.648 -0.016 2.272a1.593 1.593 0 0 0 2.256 0l9.264 -9.264 9.264 9.264c0.64 0.608 1.648 0.592 2.256 -0.032a1.614 1.614 0 0 0 0 -2.224l-9.248 -9.264 9.264 -9.264c0.608 -0.64 0.592 -1.648 -0.032 -2.256a1.614 1.614 0 0 0 -2.224 0l-9.264 9.248 -9.264 -9.264c-0.608 -0.64 -1.632 -0.656 -2.256 -0.032s-0.656 1.632 -0.032 2.256z" className="path-ops" /></g></g></g></g></svg></button>
                                    </div>
                                </div>
                                <br />
                                <br />

                                <h4>Количество знаков после запятой: {this.state.precision}</h4>
                                <Slider min="0" max="20" value={this.state.precision} handler={(e) => { this.setState({ precision: e.target.value }) }} />

                                <br />
                                <button id="result" className={this.state.sentButtonColor} type="submit" onClick={() => { this.setState({ button: 1 }) }}>{'\u00A0'} Вычислить {'\u00A0'}</button>
                            </center>

                            <center>
                                <Result value={this.state.result} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => { this.setState({ button: 2 }); }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates} elementName="Pow" /></center>
                        </form>
                    </center>

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по подсчёту числовых выражений:</h3></center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям предоставлен функционал, позволяющий вычислять значения различных числовых выражений. Выражения могут содержать целые и вещественные числа в различных степенях, степени так же могут быть как целыми, так и вещественными, все числа в выражении записываются в десятичной системе счисления. В выражении могут присутствовать такие операции, как сложение, вычитание, умножение, деление и возведение в степень. Так же выражение может содержать кргулые скобки и знаки '.' и ',' для выделения дробной части числа. Сервис способен производить вычисления числовых выражений, не содержащих нуля в знаменателе и выражений, результат которых не будет превышать 4300 символов в длинну, в противном случае в качетве результата будет записан знак бесконечности. Чтобы получить результат выражения достаточно ввести выражение в поле для ввода, выбрать с помощью ползунка точность результата, после чего нажать на кнопку "Вычислить".</p>
                    </div>
                </main >

                <Footer />
            </div >
        );
    }
}

export default PagePow;
