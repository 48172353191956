import React from "react";

class PowerInputText extends React.Component {
    max(a, b) {
        return (a > b) ? a : b;
    }

    componentDidUpdate() {
        if (document.getElementById("cursor")) {
            if (document.getElementById("cursor").getBoundingClientRect().x - 10 < document.getElementById("scroll-section").getBoundingClientRect().x) {
                document.getElementById("scroll-section").scrollBy(-(document.getElementById("scroll-section").getBoundingClientRect().x - document.getElementById("cursor").getBoundingClientRect().x) - 10, 0);
            } else if ((document.getElementById("scroll-section").getBoundingClientRect().width + document.getElementById("scroll-section").getBoundingClientRect().x) < document.getElementById("cursor").getBoundingClientRect().x + 10) {
                document.getElementById("scroll-section").scrollBy(document.getElementById("cursor").getBoundingClientRect().x - document.getElementById("scroll-section").getBoundingClientRect().x - document.getElementById("scroll-section").getBoundingClientRect().width + 10, 0);
            }
        }
    }

    findPosWithIndex(str, index) {
        if (index >= str.length) {
            return null;
        } else if (index < 0) {
            return -1;
        }

        let positionInLevel = 0;
        let space = "";

        for (let i = 0; i < index; ++i) {
            space = "";
            ++positionInLevel;

            if (str[i] === '^') {
                let rem = i;
                let bracketsCount = 0;
                let power = "";
                do {
                    ++i;
                    if (str[i].charCodeAt(0) === 1) {
                        ++bracketsCount;
                    } else if (str[i].charCodeAt(0) === 2) {
                        --bracketsCount;
                    }
                    power += str[i];
                } while (bracketsCount > 0);

                let res = this.findPosWithIndex(power.slice(1, power.length - 1), index - 2 - rem);
                --positionInLevel;
                if (res !== null) {
                    return positionInLevel + " " + res;
                } else {
                    space = " ";
                }
                --i;
            }
        }

        return positionInLevel + space;
    }

    createInput(str, level, position, cursor, id) {
        let positionInLevel = -1;
        let pow = false;
        let power = "";
        let bracketsCount = 0;
        let arr = [];

        for (let i = -1; i < str.length; ++i) {
            arr.push(i);
        }

        return (
            <React.Fragment key={id++}>
                {
                    arr.map((i) => {
                        if (!pow) {
                            if (str[i] !== '^') {
                                if (cursor === (position + positionInLevel++)) {
                                    return (
                                        <React.Fragment key={id++}>
                                            {
                                                (i >= 0) ? (<span id={position + (positionInLevel - 1)} className="symbol" onClick={(e) => { this.props.setCursor(e); }}>
                                                    {(str[i] !== ' ') ? str[i] : '\u00A0'}
                                                </span>) : (<></>)
                                            }
                                            <span id="cursor" className="cursor" onClick={(e) => { e.preventDefault(); }}>&nbsp;</span>
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <React.Fragment key={id++}>
                                        {
                                            (i >= 0) ? (<span id={position + (positionInLevel - 1)} className="symbol" onClick={(e) => { this.props.setCursor(e); }}>
                                                {(str[i] !== ' ') ? str[i] : '\u00A0'}
                                            </span>) : (<></>)
                                        }
                                    </React.Fragment>
                                );
                            } else {
                                positionInLevel++
                                pow = true;
                                bracketsCount = 0;
                                power = "";
                            }
                        } else {
                            power += str[i];

                            if (str[i].charCodeAt(0) === 1) {
                                bracketsCount++;
                            } else if (str[i].charCodeAt(0) === 2) {
                                bracketsCount--;
                            }

                            if (!bracketsCount) {
                                pow = false;

                                return (
                                    <React.Fragment key={id++}>
                                        <span id={position + (positionInLevel - 1) + " "} className={"power"} onClick={(e) => { this.props.setCursor(e) }}>
                                            {this.createInput(power.slice(1, power.length - 1), level + 1, position + (positionInLevel - 1) + " ", cursor, id)}
                                        </span>
                                        {
                                            ((position + (positionInLevel - 1) + " ") === cursor) ? <span id="cursor" key={id++} className="cursor" onClick={(e) => { e.preventDefault() }}>&nbsp;</span > : <React.Fragment key={id++}></React.Fragment>
                                        }
                                    </React.Fragment>
                                );
                            }
                        }

                        return <React.Fragment key={id++}></React.Fragment>
                    })
                }
            </React.Fragment >
        );
    }

    getSelectionStart(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveEnd('character', o.value.length)
            if (r.text === '') return o.value.length
            return o.value.lastIndexOf(r.text)
        } else return o.selectionStart
    }

    getSelectionEnd(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveStart('character', -o.value.length)
            return r.text.length
        } else return o.selectionEnd
    }

    render() {
        if (!this.props.inputField) {
            return (
                <div id="scroll-section" className="pdt4">
                    <span className={"first-line"}>
                        {this.createInput("Введите выражение", 0, "", "", 0)}
                    </span>
                </div >
            )
        }

        let str = this.props.inputField.value;
        let res = "";
        let cursor = this.props.cursor;

        for (let i = 0; i < str.length; ++i) {
            if (str[i] === "^") {
                if (i === 0 || str[i - 1].charCodeAt(0) === 1) {
                    res += " ";

                    if (i < this.props.cursor) {
                        cursor += 1;
                    }
                }
            }

            res += str[i];
        }

        let position = -1;

        if (this.findPosWithIndex(res, cursor)) {
            position = this.findPosWithIndex(res, cursor).toString();
        }

        if (res.length === 0) {
            if (cursor === -1) {
                position = "7";
            }
            res = "Введите выражение";
        }

        // document.getElementById("cursor-position").value = position.toString() + " " + document.getElementById("cursor");

        return (
            <div id="scroll-section" className="pdt4">
                <span className={"first-line"}>
                    {this.createInput(res, 0, "", position, 0)}
                </span>
            </div >
        );
    }
}

export default PowerInputText;