import React from "react";

class Slider extends React.Component {
    render() {
        return (
            <div className="slider">
                <input type="range" min={this.props.min} max={this.props.max} value={this.props.value} className="slider" id="myRange" onChange={(e) => { this.props.handler(e) }}></input>
            </div>
        );
    }
}

export default Slider;