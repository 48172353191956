import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import CodingResult from "../../components/Results/CodingResult.js";
import Slider from "../../components/Slider/Slider.js"
import HeadData from "../../components/HeadData/HeadData";

var bigInt = require("big-integer");
const CODING_REST_URL = "https://math-instruments.ru:8443/NumberCoding";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class PageNumberCoding extends React.Component {
    normalizeString(str) {
        while (str[0] === " ") {
            str = str.slice(1);
        }
        while (str[str.length - 1] === " ") {
            str = str.slice(0, str.length - 1);
        }
        return str;
    }

    constructor(props) {
        super(props);

        this.state = {
            button: 1,
            number: "",
            directСode: "",
            reverseСode: "",
            additionalСode: "",
            dimension: 32,
            max: 2147483647,
            min: -2147483648,
            sentButtonColor: "big-bad-button",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setDemension = this.setDemension.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(CODING_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ number: response.data.number });
                this.setState({ directСode: response.data.directCode });
                this.setState({ reverseСode: response.data.reverseCode });
                this.setState({ additionalСode: response.data.additionalCode });

                let limit = bigInt(Math.pow(2, response.data.dimension - 1), 10);
                let min = bigInt(-limit, 10);
                let max = bigInt(limit.subtract(1), 10);
                this.setState({ dimension: response.data.dimension });
                this.setState({ min: min.toString(10) });
                this.setState({ max: max.toString(10) });

                this.handleInputChange(response.data.number);
            } else {
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if (this.state.sentButtonColor === "big-bad-button" && !(this.state.button === 2)) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        var formData = new FormData();
        formData.append('number', this.normalizeString(this.state.number));
        formData.append('dimension', this.state.dimension);
        formData.append('button', this.state.button);

        axios.post(CODING_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ number: response.data.number });
                this.setState({ directСode: response.data.directCode });
                this.setState({ reverseСode: response.data.reverseCode });
                this.setState({ additionalСode: response.data.additionalCode });

                let limit = bigInt(Math.pow(2, response.data.dimension - 1), 10);
                let min = bigInt(-limit, 10);
                let max = bigInt(limit.subtract(1), 10);
                this.setState({ dimension: response.data.dimension });
                this.setState({ min: min.toString(10) });
                this.setState({ max: max.toString(10) });

                this.handleInputChange(response.data.number);
            } else {
                this.setState({ directСode: "Введённое число содержит некорректные символы или выходит за рамки разрядности." });
                this.setState({ reverseСode: "Введённое число содержит некорректные символы или выходит за рамки разрядности." });
                this.setState({ additionalСode: "Введённое число содержит некорректные символы или выходит за рамки разрядности." });
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })

        if (this.state.button !== 2) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }
    }

    setPreviousFormStates(e) {
        this.setState({ number: e.number });
        this.setState({ directСode: e.directCode });
        this.setState({ reverseСode: e.reverseCode });
        this.setState({ additionalСode: e.additionalCode });

        let limit = bigInt(Math.pow(2, e.dimension - 1), 10);
        let min = bigInt(-limit, 10);
        let max = bigInt(limit.subtract(1), 10);
        this.setState({ dimension: e.dimension });
        this.setState({ min: min.toString(10) });
        this.setState({ max: max.toString(10) });

        this.setState({ sentButtonColor: "big-good-button" });

        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    handleInputChange(str) {
        this.setState({ number: str });

        str = this.normalizeString(str);

        let template = "+-0123456789";

        for (let i = 0; i < str.length; ++i) {
            if ((str[i] === '+' || str[i] === '-')) {
                if (i !== 0 || str.length === 1) {
                    this.setState({ sentButtonColor: "big-bad-button" });
                    return;
                }
            } else {
                if (template.indexOf(str[i]) === -1) {
                    this.setState({ sentButtonColor: "big-bad-button" });
                    return;
                }
            }
        }

        let num = bigInt(str, 10);
        let min = bigInt(this.state.min, 10);
        let max = bigInt(this.state.max, 10);

        if (num.compare(min) >= 0 && num.compare(max) <= 0 && str) {
            this.setState({ sentButtonColor: "big-good-button" });
        } else {
            this.setState({ sentButtonColor: "big-bad-button" });
        }
    }

    setDemension(e) {
        let selectDimensionArr = [8, 16, 32, 64, 128];
        let value = e.target.value;

        for (let i = 1; i < selectDimensionArr.length; ++i) {
            if (value > selectDimensionArr[i - 1] && value < selectDimensionArr[i]) {
                if ((value - selectDimensionArr[i - 1]) < (selectDimensionArr[i] - value)) {
                    value = selectDimensionArr[i - 1];
                } else {
                    value = selectDimensionArr[i];
                }
            }
        }

        let limit = bigInt(Math.pow(2, value - 1), 10);
        let min = bigInt(-limit, 10);
        let max = bigInt(limit.subtract(1), 10);
        this.setState({ dimension: value });
        this.setState({ min: min.toString(10) });
        this.setState({ max: max.toString(10) });

        if (!this.state.number) {
            return;
        }

        let num = bigInt(this.state.number, 10);

        if (num.compare(min) >= 0 && num.compare(max) <= 0) {
            this.setState({ sentButtonColor: "big-good-button" });
        } else {
            this.setState({ sentButtonColor: "big-bad-button" });
        }
    }

    render() {
        return (
            <div className="wrapper">
                <HeadData
                    title="Прямой, дополнительный и обратный код числа – MathInstruments"
                    url="https://MathInstruments.ru/NumberCoding"
                    keys="MathInstruments, Main, MaIn, прямой код, обратный код, дополнительный код, прямой обратный код числа, перевод числа в обратный код, обратный код онлайн, онлайн, перевод кодировки числа, кодировки числа, прямой обратный и дополниьельный код, математические калькуляторы, калькуляторы, математические инструменты"
                    description="На данной странице пользователи могут производить перевод чисел в прямой, обратный и дополнительный коды.."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form">
                        <h2>Получения прямого, обратного и дополнительного кода числа.</h2>

                        <form onSubmit={this.handleSubmit}>
                            <center className="my-border">
                                <h3>Число для перевода:</h3>
                                <input className="form_control em315" type="text" placeholder="Введите число для перевода из диапазона" value={this.state.number} onChange={(e) => { this.handleInputChange(e.target.value); }} />
                                <h3>Диапазон значений:</h3>
                                <h4 className="diaposon">[{this.state.min}, {this.state.max}]</h4>
                                <br />

                                <h4>Разрядность числа: {this.state.dimension}</h4>
                                <div className="big-slider">
                                    <Slider min="8" max="128" value={this.state.dimension} handler={(e) => { this.setDemension(e) }} />
                                </div>

                                <br />
                                <button id="result" className={this.state.sentButtonColor} type="submit" onClick={() => { this.setState({ button: 1 }) }}>{'\u00A0'} Рассчитать {'\u00A0'}</button>
                            </center>

                            <center>
                                <CodingResult directСode={this.state.directСode} reverseСode={this.state.reverseСode} additionalСode={this.state.additionalСode} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => { this.setState({ button: 2 }); }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates} elementName="Coding" /></center>
                        </form>
                    </center>

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по переводу чисел:</h3></center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям предоставлен функционал, позволяющий представлять целые десятичные числа в виде прямого, обратного и дополнительного кода. Сами числа могут содержать только арабские цифры, а также символы "+", "-" для смены знака числа(не обязательны). Число может содержать незначащие нули, однако не может состоять только из символов "+" или "-", а также не может содержать в себе больше одного символа, определяющего знак числа. Чтобы получить коды, ползунком выберите разрядность числа, чтобы определить диапазон допустимых значений для ввода, затем введите исходное число, которое принадлежит диапазону чисел с заданной разрядностью, в поле ввода, после чего нажмите кнопку "Рассчитать".</p>
                    </div>
                </main >

                <Footer />
            </div >
        );
    }
}

export default PageNumberCoding;
