import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import SelectBlock from "../../components/Select/SelectBlock.js";
import Slider from "../../components/Slider/Slider.js";
import Result from "../../components/Results/Result.js";
import HeadData from "../../components/HeadData/HeadData";

const TRANSLATOR_REST_URL = "https://math-instruments.ru:8443/NumberTranslator";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class PageNumberTranslator extends React.Component {
  fromArr = [
    { value: 2, label: "двоичной" },
    { value: 3, label: "троичной" },
    { value: 4, label: "четверичной" },
    { value: 5, label: "пятеричной" },
    { value: 6, label: "шестеричной" },
    { value: 7, label: "семеричной" },
    { value: 8, label: "восьмеричной" },
    { value: 9, label: "девятеричной" },
    { value: 10, label: "десятичной" },
    { value: 11, label: "одинадцатеричной" },
    { value: 12, label: "двенадцатиричной" },
    { value: 13, label: "тринадцатиричной" },
    { value: 14, label: "четырнадцатеричной" },
    { value: 15, label: "пятнадцатиричной" },
    { value: 16, label: "шестнадцатеричной" },
  ];
  toArr = [
    { value: 2, label: "двоичную" },
    { value: 3, label: "троичную" },
    { value: 4, label: "четверичную" },
    { value: 5, label: "пятеричную" },
    { value: 6, label: "шестеричную" },
    { value: 7, label: "семеричную" },
    { value: 8, label: "восьмеричную" },
    { value: 9, label: "девятеричную" },
    { value: 10, label: "десятичную" },
    { value: 11, label: "одинадцатеричную" },
    { value: 12, label: "двенадцатиричную" },
    { value: 13, label: "тринадцатиричную" },
    { value: 14, label: "четырнадцатеричную" },
    { value: 15, label: "пятнадцатиричную" },
    { value: 16, label: "шестнадцатеричную" },
  ];

  normalizeString(str) {
    while (str[0] === " ") {
      str = str.slice(1);
    }
    while (str[str.length - 1] === " ") {
      str = str.slice(0, str.length - 1);
    }
    return str;
  }

  constructor(props) {
    super(props);

    this.state = {
      button: 1,
      number: "",
      precision: 0,
      result: "",
      baseFrom: 10,
      baseTo: 2,
      sentButtonColor: "big-bad-button",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const inputs = document.querySelectorAll("input");

    inputs.forEach((input) => {
      input.setAttribute("autocomplete", "off");
      input.setAttribute("autocorrect", "off");
      input.setAttribute("autocapitalize", "off");
      input.setAttribute("spellcheck", false);
    });

    axios
      .get(TRANSLATOR_REST_URL, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ number: response.data.number });
          this.setState({ precision: response.data.precision });
          this.setState({ result: response.data.result });
          this.setState({ baseFrom: response.data.baseFrom });
          this.setState({ baseTo: response.data.baseTo });
        }
        this.handleInputChange(response.data.number, response.data.baseFrom);
      });
  }

  handleSubmit = async (props) => {
    props.preventDefault();

    if (
      this.state.sentButtonColor === "big-bad-button" &&
      !(this.state.button === 2)
    ) {
      if (Cookies.get("themeColor") === "dark") {
        Swal.fire(DarkAlert);
      } else {
        Swal.fire(LightAlert);
      }

      return;
    }

    var formData = new FormData();
    formData.append("number", this.normalizeString(this.state.number));
    formData.append("precision", this.state.precision);
    formData.append("baseFrom", this.state.baseFrom);
    formData.append("baseTo", this.state.baseTo);
    formData.append("button", this.state.button);

    axios
      .post(TRANSLATOR_REST_URL, formData, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ number: response.data.number });
          this.setState({ precision: response.data.precision });
          this.setState({ result: response.data.result });
          this.setState({ baseFrom: response.data.baseFrom });
          this.setState({ baseTo: response.data.baseTo });

          this.handleInputChange(response.data.number, response.data.baseFrom);
        } else {
          this.setState({
            result: "Введённое число содержит некорректные символы.",
          });
          this.setState({ sentButtonColor: "big-bad-button" });
        }
      });

    if (this.state.button !== 2) {
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });
    } else {
      document.getElementById("input").scrollIntoView({ behavior: "smooth" });
      await delay(350);
    }
  };

  setPreviousFormStates(e) {
    this.setState({ number: e.number });
    this.setState({ precision: e.precision });
    this.setState({ result: e.result });
    this.setState({ baseFrom: e.baseFrom });
    this.setState({ baseTo: e.baseTo });
    this.setState({ sentButtonColor: "big-good-button" });

    document.getElementById("input").scrollIntoView({ behavior: "smooth" });
  }

  handleInputChange(str, base) {
    this.setState({ number: str });

    str = this.normalizeString(str);

    str = str.toLowerCase().replaceAll(",", ".");
    let template = "+-.0123456789abcdef";

    if (
      str.split(".").length - 1 > 1 ||
      str.split("+").length - 1 > 1 ||
      str.split("-").length - 1 > 1 ||
      str.length === 0
    ) {
      this.setState({ sentButtonColor: "big-bad-button" });
      return;
    }

    for (let i = 0; i < str.length; ++i) {
      if (str[i] === "+" || str[i] === "-") {
        if (i !== 0 || str.length === 1) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else if (str[i] === ".") {
        if (
          i === 0 ||
          i === str.length - 1 ||
          ((str[0] === "+" || str[0] === "-") && i === 1)
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else {
        if (
          template.indexOf(str[i]) === -1 ||
          template.indexOf(str[i]) - 3 >= base
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      }
    }

    this.setState({ sentButtonColor: "big-good-button" });
  }

  render() {
    return (
      <div className="wrapper">
        <HeadData
          title="Перевод чисел в различные системы счисления – MathInstruments"
          url="https://MathInstruments.ru/NumberTranslator"
          keys="MathInstruments, Main, MaIn, перевод чисел в различные системы счисления, системы счисления, перевод числа из одной системы счисления в дргугую, перевод чисел из одной системы счисления в другую онлайн, онлайн, математические калькуляторы, калькуляторы, математические инструменты"
          description="На данной странице представлен функционал, позволяющий пользователям переводить целые и вещественные числа из одной системы счисления в другую с заданной точностью."
        />

        <Header updater={() => this.forceUpdate()} />

        <main className="content">
          <center id="input" className="form">
            <h2>Перевод чисел в различные системы счисления.</h2>
            <form onSubmit={this.handleSubmit}>
              <center className="my-border">
                <h3>Число для перевода:</h3>
                <input
                  className="form_control em20"
                  type="text"
                  placeholder="Введите число для перевода"
                  value={this.state.number}
                  onChange={(e) => {
                    this.handleInputChange(e.target.value, this.state.baseFrom);
                  }}
                />

                <div className="selectTranslation">
                  <h3>Из</h3>
                  <div className="select">
                    <SelectBlock
                      onChange={(e) => {
                        this.setState({ baseFrom: e.value });
                        this.handleInputChange(this.state.number, e.value);
                      }}
                      options={this.fromArr}
                      value={this.fromArr[this.state.baseFrom - 2]}
                    />
                  </div>
                </div>

                <div className="selectTranslation">
                  <h3>В</h3>
                  <div className="select">
                    <SelectBlock
                      onChange={(e) => {
                        this.setState({ baseTo: e.value });
                      }}
                      options={this.toArr}
                      value={this.toArr[this.state.baseTo - 2]}
                    />
                  </div>
                </div>
                <br />
                <br />
                <br />

                <h4>Количество знаков после запятой: {this.state.precision}</h4>
                <Slider
                  min="0"
                  max="20"
                  value={this.state.precision}
                  handler={(e) => {
                    this.setState({ precision: e.target.value });
                  }}
                />

                <br />
                <button
                  id="result"
                  className={this.state.sentButtonColor}
                  type="submit"
                  onClick={() => {
                    this.setState({ button: 1 });
                  }}
                >
                  {"\u00A0"} Перевести {"\u00A0"}
                </button>
              </center>

              <center>
                <Result value={this.state.result} />
              </center>

              <button
                className="clear-button"
                type="submit"
                onClick={() => {
                  this.setState({ button: 2 });
                }}
              >
                <svg viewBox="0 0 448 512" className="svgIcon">
                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                </svg>
              </button>

              <center>
                <PreviousActions
                  handleClick={this.setPreviousFormStates}
                  elementName="Translation"
                />
              </center>
            </form>
          </center>

          <div className="form pd05">
            <center>
              <h3 id="info">Инструкция по переводу чисел:</h3>
            </center>
            <p>
              {" "}
              {"\u00A0"} {"\u00A0"} {"\u00A0"} {"\u00A0"} На данной странице
              пользователям предоставлен функционал, позволяющий найти польскую
              обратную для функций алгебры логики и арифметики. Вид переменной -
              это последовательность латинских символов и цифр, у которой первый
              символ всегда латинская буква. Недопустимой строкой является
              строка, где смешаны арифметические и логические операнды. Для
              получения ответа надо нажать кнопку "Вычислить".
            </p>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

export default PageNumberTranslator;
