import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import SelectBlock from "../../components/Select/SelectBlock.js";
import Result from "../../components/Results/Result.js";
import Slider from "../../components/Slider/Slider.js";
import HeadData from "../../components/HeadData/HeadData";

const OPERATIONS_REST_URL = "https://math-instruments.ru:8443/NumberOperations";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class PageNumberOperations extends React.Component {
  selectBaseArr = [];
  selectPrecisionArr = [];
  selectOperationArr = [
    { value: "+", label: "+" },
    { value: "-", label: "-" },
    { value: "*", label: "*" },
    { value: "/", label: "/" },
  ];
  baseArr = [
    { value: 2, label: "двоичная" },
    { value: 3, label: "троичная" },
    { value: 4, label: "четверичная" },
    { value: 5, label: "пятеричная" },
    { value: 6, label: "шестеричная" },
    { value: 7, label: "семеричная" },
    { value: 8, label: "восьмеричная" },
    { value: 9, label: "девятеричная" },
    { value: 10, label: "десятичная" },
    { value: 11, label: "одинадцатеричная" },
    { value: 12, label: "двенадцатиричная" },
    { value: 13, label: "тринадцатиричная" },
    { value: 14, label: "четырнадцатеричная" },
    { value: 15, label: "пятнадцатиричная" },
    { value: 16, label: "шестнадцатеричная" },
  ];

  normalizeString(str) {
    while (str[0] === " ") {
      str = str.slice(1);
    }
    while (str[str.length - 1] === " ") {
      str = str.slice(0, str.length - 1);
    }
    return str;
  }

  constructor(props) {
    super(props);

    this.state = {
      button: 1,
      firstOperand: "",
      secondOperand: "",
      firstOperandBase: 10,
      secondOperandBase: 10,
      operation: "+",
      resultBase: 10,
      precision: 0,
      result: "",
      sentButtonColor: "big-bad-button",
    };

    for (let i = 0; i <= 20; ++i) {
      if (i >= 2 && i <= 16) {
        this.selectBaseArr[i] = i;
      }
      this.selectPrecisionArr[i] = i;
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
  }

  componentDidMount() {
    const inputs = document.querySelectorAll("input");

    inputs.forEach((input) => {
      input.setAttribute("autocomplete", "off");
      input.setAttribute("autocorrect", "off");
      input.setAttribute("autocapitalize", "off");
      input.setAttribute("spellcheck", false);
    });

    axios
      .get(OPERATIONS_REST_URL, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ firstOperand: response.data.firstOperand });
          this.setState({ secondOperand: response.data.secondOperand });
          this.setState({ firstOperandBase: response.data.firstOperandBase });
          this.setState({ secondOperandBase: response.data.secondOperandBase });
          this.setState({ operation: response.data.operation });
          this.setState({ resultBase: response.data.resultBase });
          this.setState({ precision: response.data.precision });
          this.setState({ result: response.data.result });
        }
        this.handleInputChange(
          response.data.firstOperand,
          response.data.firstOperandBase,
          response.data.secondOperand,
          response.data.secondOperandBase,
          response.data.operation
        );
      });
  }

  handleSubmit = async (props) => {
    props.preventDefault();

    if (
      this.state.sentButtonColor === "big-bad-button" &&
      !(this.state.button === 2)
    ) {
      if (Cookies.get("themeColor") === "dark") {
        Swal.fire(DarkAlert);
      } else {
        Swal.fire(LightAlert);
      }

      return;
    }

    var formData = new FormData();
    formData.append(
      "firstOperand",
      this.normalizeString(this.state.firstOperand)
    );
    formData.append(
      "secondOperand",
      this.normalizeString(this.state.secondOperand)
    );
    formData.append("firstOperandBase", this.state.firstOperandBase);
    formData.append("secondOperandBase", this.state.secondOperandBase);
    formData.append("operation", this.state.operation);
    formData.append("resultBase", this.state.resultBase);
    formData.append("precision", this.state.precision);
    formData.append("button", this.state.button);

    axios
      .post(OPERATIONS_REST_URL, formData, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ firstOperand: response.data.firstOperand });
          this.setState({ secondOperand: response.data.secondOperand });
          this.setState({ firstOperandBase: response.data.firstOperandBase });
          this.setState({ secondOperandBase: response.data.secondOperandBase });
          this.setState({ operation: response.data.operation });
          this.setState({ resultBase: response.data.resultBase });
          this.setState({ precision: response.data.precision });
          this.setState({ result: response.data.result });

          this.handleInputChange(
            response.data.firstOperand,
            response.data.firstOperandBase,
            response.data.secondOperand,
            response.data.secondOperandBase,
            response.data.operation
          );
        } else {
          this.setState({
            result:
              "Было произведено деление на ноль, либо один из операндов введен некорректно.",
          });
          this.setState({ sentButtonColor: "big-bad-button" });
        }
      });

    if (this.state.button !== 2) {
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });
    } else {
      document.getElementById("input").scrollIntoView({ behavior: "smooth" });
      await delay(350);
    }
  };

  setPreviousFormStates(e) {
    this.setState({ firstOperand: e.firstOperand });
    this.setState({ secondOperand: e.secondOperand });
    this.setState({ firstOperandBase: e.firstOperandBase });
    this.setState({ secondOperandBase: e.secondOperandBase });
    this.setState({ operation: e.operation });
    this.setState({ resultBase: e.resultBase });
    this.setState({ precision: e.precision });
    this.setState({ result: e.result });
    this.setState({ sentButtonColor: "big-good-button" });

    document.getElementById("input").scrollIntoView({ behavior: "smooth" });
  }

  handleInputChange(
    firstOperand,
    firstOperandBase,
    secondOperand,
    secondOperandBase,
    operation
  ) {
    firstOperand = firstOperand.toLowerCase().replaceAll(",", ".");
    secondOperand = secondOperand.toLowerCase().replaceAll(",", ".");
    let template = "+-.0123456789abcdef";

    this.setState({ firstOperand: firstOperand });
    this.setState({ secondOperand: secondOperand });

    firstOperand = this.normalizeString(firstOperand);
    secondOperand = this.normalizeString(secondOperand);

    if (
      firstOperand.split(".").length - 1 > 1 ||
      firstOperand.split("+").length - 1 > 1 ||
      firstOperand.split("-").length - 1 > 1 ||
      firstOperand.length === 0
    ) {
      this.setState({ sentButtonColor: "big-bad-button" });
      return;
    }

    if (
      secondOperand.split(".").length - 1 > 1 ||
      secondOperand.split("+").length - 1 > 1 ||
      secondOperand.split("-").length - 1 > 1 ||
      secondOperand.length === 0
    ) {
      this.setState({ sentButtonColor: "big-bad-button" });
      return;
    }

    for (let i = 0; i < firstOperand.length; ++i) {
      if (firstOperand[i] === "+" || firstOperand[i] === "-") {
        if (i !== 0 || firstOperand.length === 1) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else if (firstOperand[i] === ".") {
        if (
          i === 0 ||
          i === firstOperand.length - 1 ||
          ((firstOperand[0] === "+" || firstOperand[0] === "-") && i === 1)
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else {
        if (
          template.indexOf(firstOperand[i]) === -1 ||
          template.indexOf(firstOperand[i]) - 3 >= firstOperandBase
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      }
    }

    for (let i = 0; i < secondOperand.length; ++i) {
      if (secondOperand[i] === "+" || secondOperand[i] === "-") {
        if (i !== 0 || secondOperand.length === 1) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else if (secondOperand[i] === ".") {
        if (
          i === 0 ||
          i === secondOperand.length - 1 ||
          ((secondOperand[0] === "+" || secondOperand[0] === "-") && i === 1)
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      } else {
        if (
          template.indexOf(secondOperand[i]) === -1 ||
          template.indexOf(secondOperand[i]) - 3 >= secondOperandBase
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          return;
        }
      }
    }

    if (operation === "/") {
      if (
        secondOperand
          .replaceAll(".", "")
          .replaceAll("0", "")
          .replaceAll("-", "")
          .replaceAll("+", "").length === 0
      ) {
        this.setState({ sentButtonColor: "big-bad-button" });
        return;
      }
    }

    this.setState({ sentButtonColor: "big-good-button" });
  }

  render() {
    return (
      <div className="wrapper">
        <HeadData
          title="Сложение, умножение, вычитание и диление чисел в различных системах счисления – MathInstruments"
          url="https://MathInstruments.ru/NumberOperations"
          keys="MathInstruments, Main, MaIn, калькулятор чисел в разных системах счисления, сложение чисел, умножение чисел, вычитание чисел, деление чисел, сложение чисел в различных системах счисления онлайн, сложение чисел в различных системах счисления, онлайн, умножение чисел в различных системах счисления, вычитание чисел в различных системах счисления, деление чисел в различных системах счисления, системы счисления, математические калькуляторы, калькуляторы, математические инструменты"
          description="На данной странице мы предлагаем пользователям функционал, позволяющий выполнять арифметические операции над числами в различных системах счисления и получать результат с заданной точностью.."
        />

        <Header updater={() => this.forceUpdate()} />

        <main className="content">
          <center id="input" className="form">
            <h2>Операции над числами в различных системах счисления.</h2>
            <form onSubmit={this.handleSubmit}>
              <center className="my-border">
                <div className="operands">
                  <div className="operand">
                    <div className="my-border operand-border em20">
                      <label>Первый операнд:</label>
                      <input
                        className="form_control em16 no-padding"
                        type="text"
                        placeholder="Введите первый операнд"
                        value={this.state.firstOperand}
                        onChange={(e) => {
                          this.handleInputChange(
                            e.target.value,
                            this.state.firstOperandBase,
                            this.state.secondOperand,
                            this.state.secondOperandBase,
                            this.state.operation
                          );
                        }}
                      />

                      <label className="sub-label">Система счисления:</label>
                      <div className="select">
                        <SelectBlock
                          onChange={(e) => {
                            this.setState({ firstOperandBase: e.value });
                            this.handleInputChange(
                              this.state.firstOperand,
                              e.value,
                              this.state.secondOperand,
                              this.state.secondOperandBase,
                              this.state.operation
                            );
                          }}
                          options={this.baseArr}
                          value={this.baseArr[this.state.firstOperandBase - 2]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="operand">
                    <div className="my-border operand-border mr min-border">
                      <div className="sub-my-border">
                        <label>Операция</label>
                        <div className="select-mini">
                          <SelectBlock
                            onChange={(e) => {
                              this.setState({ operation: e.value });
                              this.handleInputChange(
                                this.state.firstOperand,
                                this.state.firstOperandBase,
                                this.state.secondOperand,
                                this.state.secondOperandBase,
                                e.value
                              );
                            }}
                            options={this.selectOperationArr}
                            value={{
                              value: this.state.operation,
                              label: this.state.operation,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="operand">
                    <div className="my-border operand-border em20">
                      <label>Второй операнд:</label>
                      <input
                        className="form_control em16 no-padding"
                        type="text"
                        placeholder="Введите второй операнд"
                        value={this.state.secondOperand}
                        onChange={(e) => {
                          this.handleInputChange(
                            this.state.firstOperand,
                            this.state.firstOperandBase,
                            e.target.value,
                            this.state.secondOperandBase,
                            this.state.operation
                          );
                        }}
                      />
                      <label className="sub-label">Система счисления:</label>
                      <div className="select">
                        <SelectBlock
                          onChange={(e) => {
                            this.setState({ secondOperandBase: e.value });
                            this.handleInputChange(
                              this.state.firstOperand,
                              this.state.firstOperandBase,
                              this.state.secondOperand,
                              e.value,
                              this.state.operation
                            );
                          }}
                          options={this.baseArr}
                          value={this.baseArr[this.state.secondOperandBase - 2]}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <h5 className="bottomLabel">
                  Выберите систему счисления в которой отобразится результат
                </h5>
                <div className="select">
                  <SelectBlock
                    onChange={(e) => {
                      this.setState({ resultBase: e.value });
                    }}
                    options={this.baseArr}
                    value={this.baseArr[this.state.resultBase - 2]}
                  />
                </div>

                <br />
                <br />

                <h5>Количество знаков после запятой: {this.state.precision}</h5>
                <Slider
                  min="0"
                  max="20"
                  value={this.state.precision}
                  handler={(e) => {
                    this.setState({ precision: e.target.value });
                  }}
                />

                <br />

                <button
                  id="result"
                  className={this.state.sentButtonColor}
                  type="submit"
                  onClick={() => {
                    this.setState({ button: 1 });
                  }}
                >
                  {"\u00A0"} Вычислить {"\u00A0"}
                </button>
              </center>

              <center>
                <Result value={this.state.result} />
              </center>

              <button
                className="clear-button"
                type="submit"
                onClick={() => {
                  this.setState({ button: 2 });
                }}
              >
                <svg viewBox="0 0 448 512" className="svgIcon">
                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                </svg>
              </button>

              <center>
                <PreviousActions
                  handleClick={this.setPreviousFormStates}
                  elementName="Operation"
                />
              </center>
            </form>
          </center>

          <div className="form pd05">
            <center>
              <h3 id="info">
                Инструкция по использованию операций над числами:
              </h3>
            </center>
            <p>
              {" "}
              {"\u00A0"} {"\u00A0"} {"\u00A0"} {"\u00A0"} На данной странице
              пользователям предоставлен функционал, позволяющий складывать,
              вычитать, делить и умножать целые и дробные числа в произвольных
              системах счисления и получать результат с заданной точностью.
              Основания систем счисления могут варироваться от 2 до 16. Сами
              числа могут содержать только строчные и заглавные латинские буквы,
              арабские цифры, символы "+", "-" для смены знака числа(не
              обязательны) а также ".", "," для разделения дробной и
              вещественной частей чисел. Чтобы произвести операцию, введите
              исходные числа в соответствующие поля ввода, затем укажите
              основания систем счисления введённых чисел, далее установите
              основание системы счисления, в которую нужно перевести результат
              операции, ползунком выберите количество знаков после запятой, с
              которым вы хотите получить результат, после чего нажмите кнопку
              "Вычислить".
            </p>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

export default PageNumberOperations;
