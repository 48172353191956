import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import SelectBlock from "../../components/Select/SelectBlock.js";
import Matrix from "../../components/Matrix/MatrixCreator.js";
import ResultMatrix from "../../components/Results/ResultMatrixCreator.js";
import ResultDeterminant from "../../components/Results/ResultDeterminant.js";
import HeadData from "../../components/HeadData/HeadData";

const ONE_MATRIX_OPERATIONS_REST_URL = "https://math-instruments.ru:8443/OneMatrixOperations";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class PageOneMatrixOperations extends React.Component {
    normalizeString(str) {
        while (str[0] === " ") {
            str = str.slice(1);
        }
        while (str[str.length - 1] === " ") {
            str = str.slice(0, str.length - 1);
        }
        return str;
    }

    constructor(props) {
        super(props);

        this.state = {
            button: 1,
            matrix: <Matrix matrix={[[0, 0, 0], [0, 0, 0], [0, 0, 0]]} matrixId="matrixEl" onChange={() => { this.handleInputChange(null, null, null); }} />,
            determinant: "",
            resultMatrix: <ResultMatrix matrix={[[]]} />,
            matrixHeight: 3,
            matrixWidth: 3,
            matrixPow: "2",
            matrixFactor: "1",
            firstButtonColor: "previousAction",
            secondButtonColor: "previousAction",
            thirdButtonColor: "previousAction",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateMatrix = this.updateMatrix.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(ONE_MATRIX_OPERATIONS_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ matrix: <Matrix matrix={response.data.matrix} matrixId="matrixEl" onChange={(e) => { this.handleInputChange(null, null, null); }} /> });
                this.setState({ determinant: response.data.determinant });
                this.setState({ matrixHeight: response.data.matrixHeight });
                this.setState({ matrixWidth: response.data.matrixWidth });
                this.setState({ matrixPow: response.data.matrixPow });
                this.setState({ matrixFactor: response.data.matrixFactor });
                this.setState({ resultMatrix: <ResultMatrix matrix={response.data.resultMatrix} /> });
            }
            this.handleInputChange(response.data.matrix, response.data.matrixPow, response.data.matrixFactor);
        })
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if ((this.state.firstButtonColor === "small-bad-button" && this.state.button === "1") ||
            (this.state.secondButtonColor === "small-bad-button" && this.state.button === "2") ||
            (this.state.thirdButtonColor === "small-bad-button" && this.state.button === "3")) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        var formData = new FormData();
        formData.append('matrixHeight', this.state.matrixHeight);
        formData.append('matrixWidth', this.state.matrixWidth);
        formData.append('matrixPow', this.normalizeString(this.state.matrixPow));
        formData.append('matrixFactor', this.normalizeString(this.state.matrixFactor));
        formData.append('button', this.state.button);


        for (let i = 0; i < this.state.matrixHeight; ++i) {
            var subArr = [];
            for (let j = 0; j < this.state.matrixWidth; ++j) {
                subArr.push(this.normalizeString(document.getElementById('matrixEl' + i + j).value));
            }
            formData.append('matrix', subArr);
        }

        axios.post(ONE_MATRIX_OPERATIONS_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ matrix: <Matrix matrix={response.data.matrix} matrixId="matrixEl" onChange={(e) => { this.handleInputChange(null, null, null); }} /> });
                this.setState({ determinant: response.data.determinant });
                this.setState({ matrixHeight: response.data.matrixHeight });
                this.setState({ matrixWidth: response.data.matrixWidth });
                this.setState({ matrixPow: response.data.matrixPow });
                this.setState({ matrixFactor: response.data.matrixFactor });
                this.setState({ resultMatrix: <ResultMatrix matrix={response.data.resultMatrix} /> });
            } else {
                this.setState({ determinant: "Размер матрицы не подходит для подсчета выражения, либо данные матрицы введены неверно." });
            }
            this.handleInputChange(response.data.matrix, response.data.matrixPow, response.data.matrixFactor);
        })

        if (this.state.button !== 4) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }
    }

    updateMatrix(e, isHeight) {
        let matrixHeight = this.state.matrixHeight;
        let matrixWidth = this.state.matrixWidth;

        if (e) {
            if (isHeight) {
                matrixHeight = e.value;
            } else {
                matrixWidth = e.value;
            }
        }

        var arr = [];

        for (let i = 0; i < this.state.matrixHeight; ++i) {
            var subArr = [];
            for (let j = 0; j < this.state.matrixWidth; ++j) {
                subArr.push(document.getElementById('matrixEl' + i + j).value);
            }
            arr.push(subArr);
        }

        this.setState({ matrixWidth: matrixWidth });
        this.setState({ matrixHeight: matrixHeight });
        var res = [];
        for (let i = 0; i < matrixHeight; ++i) {
            var line = [];
            for (let j = 0; j < matrixWidth; ++j) {
                if (i < arr.length && j < arr[0].length) {
                    line.push(arr[i][j]);
                }
                else {
                    line.push("0");
                }
            }

            res.push(line);
        }

        this.handleInputChange(res, null, null);

        this.setState({ matrix: <Matrix matrix={res} matrixId="matrixEl" onChange={(e) => { this.handleInputChange(null, null, null); }} /> });
    }

    setPreviousFormStates(e) {
        this.setState({ matrix: <Matrix matrix={e.matrix} matrixId="matrixEl" onChange={(e) => { this.handleInputChange(null, null, null); }} /> });
        this.setState({ determinant: e.determinant });
        this.setState({ matrixHeight: e.matrixHeight });
        this.setState({ matrixWidth: e.matrixWidth });
        this.setState({ matrixPow: e.matrixPow });
        this.setState({ matrixFactor: e.matrixFactor });
        this.setState({ resultMatrix: <ResultMatrix matrix={e.resultMatrix} /> });
        this.handleInputChange(e.matrix, e.matrixPow, e.matrixFactor);
        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    isNumber(str) {
        let base = 10;
        str = str.toString().toLowerCase().replaceAll(',', '.');
        let template = "+-.0123456789abcdef";

        if ((str.split(".").length - 1) > 1 || (str.split("+").length - 1) > 1 || (str.split("-").length - 1) > 1 || str.length === 0) {
            return false;
        }

        for (let i = 0; i < str.length; ++i) {
            if ((str[i] === '+' || str[i] === '-')) {
                if (i !== 0 || str.length === 1) {
                    return false;
                }
            } else if (str[i] === '.') {
                if (i === 0 || i === (str.length - 1) || (((str[0] === '+') || (str[0] === '-')) && i === 1)) {
                    return false;
                }
            } else {
                if (template.indexOf(str[i]) === -1 || (template.indexOf(str[i]) - 3) >= base) {
                    return false;
                }
            }
        }

        return true;
    }

    handleInputChange(arr, pow, factor) {
        if (!arr) {
            arr = [];

            for (let i = 0; i < this.state.matrixHeight; ++i) {
                var subArr = [];
                for (let j = 0; j < this.state.matrixWidth; ++j) {
                    subArr.push(this.normalizeString(document.getElementById('matrixEl' + i + j).value));
                }
                arr.push(subArr);
            }
        }

        let badMatrix = false;

        for (let i = 0; i < arr.length; ++i) {
            for (let j = 0; j < arr[i].length; ++j) {
                if (!this.isNumber(this.normalizeString(arr[i][j]))) {
                    badMatrix = true;
                }
            }
        }

        if (badMatrix) {
            this.setState({ firstButtonColor: "small-bad-button" });
            this.setState({ secondButtonColor: "small-bad-button" });
            this.setState({ thirdButtonColor: "small-bad-button" });
        } else {
            this.setState({ firstButtonColor: "previousAction" });

            if (factor !== null) {
                if (this.isNumber(this.normalizeString(factor))) {
                    this.setState({ thirdButtonColor: "previousAction" });
                } else {
                    this.setState({ thirdButtonColor: "small-bad-button" });
                }
            } else {
                if (this.isNumber(this.normalizeString(this.state.matrixFactor.toString()))) {
                    this.setState({ thirdButtonColor: "previousAction" });
                } else {
                    this.setState({ thirdButtonColor: "small-bad-button" });
                }
            }

            if (arr.length === arr[0].length) {
                if (pow !== null) {
                    if (this.isNumber(this.normalizeString(pow))) {
                        this.setState({ secondButtonColor: "previousAction" });
                    } else {
                        this.setState({ secondButtonColor: "small-bad-button" });
                    }
                } else {
                    if (this.isNumber(this.normalizeString(this.state.matrixPow.toString()))) {
                        this.setState({ secondButtonColor: "previousAction" });
                    } else {
                        this.setState({ secondButtonColor: "small-bad-button" });
                    }
                }
            } else {
                this.setState({ secondButtonColor: "small-bad-button" });
            }
        }
    }

    render() {
        let size = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }, { value: 4, label: 4 }, { value: 5, label: 5 }];
        return (
            <div className="wrapper">
                <HeadData
                    title="Определитель матрицы и опереации над одной матрицей – MathInstruments"
                    url="https://MathInstruments.ru/OneMatrixOperations"
                    keys="MathInstruments, Main, MaIn, определитель матрицы, умножение матрицы, определитель онлайн, онлайн, возведение матрицы в степень, степень матрицы, определитель, математические калькуляторы, калькуляторы, математические инструменты"
                    description="На данной странице представлен функционал, позволяющий пользователям легко считать определитель матрицы, находить её степень, умножать на число, а также производить ряд дополнительных преобразований над вещественной матрицей."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form">
                        <h2>Операции над вещественной матрицей.</h2>
                        <form onSubmit={this.handleSubmit} action="/OneMatrixOperations" method="post">
                            <center className="my-border">
                                <h3 className="pb05">Укажите размерность матрицы</h3>
                                <div className='select-mini inline-block'>
                                    <SelectBlock onChange={(e) => { this.updateMatrix(e, true); }} options={size} value={{ value: this.state.matrixHeight, label: this.state.matrixHeight }} />
                                </div>
                                <span className="multi-symbol">×</span>
                                <div className='select-mini inline-block'>
                                    <SelectBlock onChange={(e) => { this.updateMatrix(e, false); }} options={size} value={{ value: this.state.matrixWidth, label: this.state.matrixWidth }} />
                                </div>

                                <br />
                                <br />
                                <br />

                                <h3>Заполните матрицу</h3>

                                {this.state.matrix}


                                <br />

                                <center className="matrix-input">
                                    <center>
                                        <button className={this.state.firstButtonColor} type="submit" onClick={() => { this.setState({ button: "1" }) }}>Вычислить определитель</button>
                                    </center>
                                    <center>
                                        <button className={this.state.secondButtonColor} type="submit" onClick={() => { this.setState({ button: "2" }) }}>Возвести в степень</button>
                                        <input className="form_control em35 inline" type="text" value={this.state.matrixPow} onChange={(e) => { this.setState({ matrixPow: e.target.value }); this.handleInputChange(null, e.target.value, null) }} />
                                    </center>
                                    <center id="result">
                                        <button className={this.state.thirdButtonColor} type="submit" onClick={() => { this.setState({ button: "3" }) }}>Умножить матрицу на</button>
                                        <input className="form_control em35 inline" type="text" value={this.state.matrixFactor} onChange={(e) => { this.setState({ matrixFactor: e.target.value }); this.handleInputChange(null, null, e.target.value) }} />
                                    </center>
                                </center>
                            </center>

                            <center>
                                <ResultDeterminant determinant={this.state.determinant} resultMatrix={this.state.resultMatrix} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => { this.setState({ button: 4 }); }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates} elementName="OneMatrixOperation" /></center>
                        </form >
                    </center >

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по работе с операциями над вещественной матрицей:</h3></center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям предоставлен функционал, позволяющий просто считать определитель вещественной матрицы, находить её степени, а также умножать матрицу на число. В ячейку матрицы может быть записано вещественное число, состоящее только из арабских цифр, символов "+" и "-" для смены знака(не обязательно), а также ".", "," для разделения дробной и вещественной частей элемента матрицы. Чтобы выполнить операцию над вещественной матрицей, достаточно задать в соответствующие поля её размерность(при этом необходимо учитывать, что некоторые операции не могут быть произведены со всеми размерностями матрицы), затем ввести значения элементов матрицы, после чего в зависимости от нужного вам действия нажать на нужную кнопку и получить результат вычислений.</p>
                    </div>
                </main >

                <Footer />
            </div >
        );
    }
}

export default PageOneMatrixOperations;
