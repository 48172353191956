import React from "react";

class ResultDeterminant extends React.Component {
    render() {
        if (this.props.determinant.length) {
            return (
                <div className="results my-border">

                    <h1>Результат:</h1>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.determinant}</output>
                </div>
            );
        } else if (this.props.resultMatrix.props.matrix[0].length) {
            return (
                <div className="results my-border">
                    <h1>Результат:</h1>

                    <center>
                        {this.props.resultMatrix}
                    </center>
                </div>
            );
        } else {
            return (<></>);
        }
    }
}

export default ResultDeterminant;