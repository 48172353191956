import React from "react";

class ResultMatrix extends React.Component {
    render() {
        var line = 0;
        let className = "matrix-form";

        return (
            <div className={className}>
                <table className='two_matrix matrix'>
                    <tbody>
                        {this.props.matrix.map((i) => {
                            var column = 0;
                            return (<tr key={line++}>
                                {
                                    i.map((j) => {
                                        return (
                                            <td key={'1' + column++} className='matrix-element-background'>
                                                <output size={3} className='form_control em35 no-padding'>{j}</output>
                                            </td>
                                        );
                                    })
                                }
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div >
        )
    }
}

export default ResultMatrix;
