let LightSelect = {
    singleValue: provided => ({
        ...provided,
        color: '#5a779e',
        backgroundColor: "#ffffff",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#ffffff",
        border: "solid 1px #5a779e",
        borderRadius: "5px",
        color: "#5a779e",
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: "#5a779e",
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: "#5a779e",
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#ffffff",
        color: "#5a779e",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        color: "#5a779e",
    }),
}

export default LightSelect;