import React from "react";
import HomeDarkBackground from "./Styles/HomeDarkBackgroundStyle";
import HomeLightBackground from "./Styles/HomeLightBackgroundStyle";
import Cookies from 'js-cookie';

class HomeBackground extends React.Component {
    render() {
        if (!Cookies.get("themeColor") || Cookies.get("themeColor") === "light") {
            return (
                <HomeLightBackground />
            )
        } else {
            return (
                <HomeDarkBackground />
            )
        }
    }
}

export default HomeBackground;
