// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-bad-button {
    --backgroundColor: rgba(246, 241, 209);
    --colorShadeA: rgb(163, 106, 106);
    --colorShadeB: rgb(186, 121, 121);
    --colorShadeC: rgb(232, 150, 150);
    --colorShadeD: rgb(232, 187, 187);
    --colorShadeE: rgb(255, 205, 205);

    margin-bottom: 2em;
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: var(--colorShadeA);
    padding: 1em 1em;
    border: 2px solid var(--colorShadeA);
    border-radius: 1em;
    background: var(--colorShadeE);
    transform-style: preserve-3d;
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.big-bad-button::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--colorShadeC);
    border-radius: inherit;
    box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);
    transform: translate3d(0, 0.75em, -1em);
    transition: all 175ms cubic-bezier(0, 0, 1, 1);
}`, "",{"version":3,"sources":["webpack://./src/css/Buttons/BigBadButton.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,iCAAiC;IACjC,iCAAiC;IACjC,iCAAiC;IACjC,iCAAiC;IACjC,iCAAiC;;IAEjC,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,yBAAyB;IACzB,gBAAgB;IAChB,oCAAoC;IACpC,kBAAkB;IAClB,8BAA8B;IAC9B,4BAA4B;IAC5B,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,sBAAsB;IACtB,yEAAyE;IACzE,uCAAuC;IACvC,8CAA8C;AAClD","sourcesContent":[".big-bad-button {\n    --backgroundColor: rgba(246, 241, 209);\n    --colorShadeA: rgb(163, 106, 106);\n    --colorShadeB: rgb(186, 121, 121);\n    --colorShadeC: rgb(232, 150, 150);\n    --colorShadeD: rgb(232, 187, 187);\n    --colorShadeE: rgb(255, 205, 205);\n\n    margin-bottom: 2em;\n    position: relative;\n    display: inline-block;\n    cursor: pointer;\n    outline: none;\n    border: 0;\n    vertical-align: middle;\n    text-decoration: none;\n    font-size: 1rem;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n    color: var(--colorShadeA);\n    padding: 1em 1em;\n    border: 2px solid var(--colorShadeA);\n    border-radius: 1em;\n    background: var(--colorShadeE);\n    transform-style: preserve-3d;\n    transition: all 175ms cubic-bezier(0, 0, 1, 1);\n}\n\n.big-bad-button::before {\n    position: absolute;\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: var(--colorShadeC);\n    border-radius: inherit;\n    box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA);\n    transform: translate3d(0, 0.75em, -1em);\n    transition: all 175ms cubic-bezier(0, 0, 1, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
