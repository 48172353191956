import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import Result from "../../components/Results/Result.js";
import HeadData from "../../components/HeadData/HeadData";

const POLISH_NOTATION_REST_URL =
  "https://math-instruments.ru:8443/PolishNotation";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class PagePolishNotation extends React.Component {
  operations = [
    "+",
    "-",
    "*",
    "/",
    "^",
    "¬",
    "≡",
    "→",
    "⊕",
    "∨",
    "∧",
    "↓",
    "↑",
    "sin",
    "cos",
    "tan",
    "cot",
    "!",
    "#",
    "№",
    "%",
  ];

  constructor(props) {
    super(props);

    this.state = {
      button: 0,
      input: "",
      normalizeInput: "",
      result: "",
      sentButtonColor: "big-bad-button",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
  }

  componentDidMount() {
    const inputs = document.querySelectorAll("input");

    inputs.forEach((input) => {
      input.setAttribute("autocomplete", "off");
      input.setAttribute("autocorrect", "off");
      input.setAttribute("autocapitalize", "off");
      input.setAttribute("spellcheck", false);
    });

    axios
      .get(POLISH_NOTATION_REST_URL, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ input: response.data.input });
          this.setState({ normalizeInput: response.data.normalizeInput });
          this.setState({ result: response.data.result });

          this.handleInputChange(response.data.input);
        } else {
          this.setState({ sentButtonColor: "big-bad-button" });
        }
      });
  }

  handleSubmit = async (props) => {
    props.preventDefault();

    if (
      this.state.sentButtonColor === "big-bad-button" &&
      !(this.state.button === 3)
    ) {
      if (Cookies.get("themeColor") === "dark") {
        Swal.fire(DarkAlert);
      } else {
        Swal.fire(LightAlert);
      }

      return;
    }

    var formData = new FormData();
    formData.append("input", this.state.input);
    formData.append("normalizeInput", this.state.normalizeInput);
    formData.append("button", this.state.button);

    axios
      .post(POLISH_NOTATION_REST_URL, formData, { withCredentials: true })
      .then((response) => {
        if (!response.data.msg) {
          this.setState({ input: response.data.input });
          this.setState({ normalizeInput: response.data.normalizeInput });
          this.setState({
            result: response.data.result.toString().replaceAll("_", " "),
          });

          this.handleInputChange(response.data.input);
        } else {
          this.setState({
            result: "Введённое число содержит некорректные символы.",
          });
          this.setState({ sentButtonColor: "big-bad-button" });
        }
      });

    if (this.state.button !== 3) {
      document.getElementById("result").scrollIntoView({ behavior: "smooth" });
    } else {
      document.getElementById("input").scrollIntoView({ behavior: "smooth" });
      await delay(350);
    }
  };

  getSelectionStart(o) {
    if (o.createTextRange) {
      var r = document.selection.createRange().duplicate();
      r.moveEnd("character", o.value.length);
      if (r.text === "") return o.value.length;
      return o.value.lastIndexOf(r.text);
    } else return o.selectionStart;
  }

  getSelectionEnd(o) {
    if (o.createTextRange) {
      var r = document.selection.createRange().duplicate();
      r.moveStart("character", -o.value.length);
      return r.text.length;
    } else return o.selectionEnd;
  }

  addOps(op) {
    let elem = document.getElementById("data");
    let s = this.getSelectionStart(elem),
      e = this.getSelectionEnd(elem);
    let str = elem.value;
    if (op.length === 3) {
      str = str.slice(0, s) + op + "()" + str.slice(e);
    } else {
      str = str.slice(0, s) + op + str.slice(e);
    }

    this.handleInputChange(str, null);

    elem.focus();
    if (op.length === 3) {
      elem.setSelectionRange(s + op.length + 1, s + op.length + 1);
    } else {
      elem.setSelectionRange(s + op.length, s + op.length);
    }
  }

  setPreviousFormStates(e) {
    this.setState({ input: e.input });
    this.setState({ normalizeInput: e.normalizeInput });
    this.setState({ result: e.result.toString().replaceAll("_", " ") });
    this.setState({ button: e.button });

    this.setState({ sentButtonColor: "big-good-button" });

    document.getElementById("input").scrollIntoView({ behavior: "smooth" });
  }

  isNumber(str) {
    if (str.length === 0) {
      return false;
    }

    if (str[0] === "+" || str[0] === "-") {
      str = str.slice(1);
    }

    let digits = "01234567890";

    let flag = true;

    for (let i = 0; i < str.length; ++i) {
      if (digits.indexOf(str[i]) === -1) {
        flag = false;
      } else if (str[i] === ".") {
        if (i === 0 || i === str.length - 1 || str.split(".").length - 1 > 1) {
          flag = false;
        }
      }
    }

    return flag;
  }
  isAlpha(ch) {
    return (
      typeof ch === "string" &&
      ch.length === 1 &&
      ((ch >= "a" && ch <= "z") || (ch >= "A" && ch <= "Z"))
    );
  }

  isDigit(c) {
    return typeof c === "string" && c.length === 1 && c >= "0" && c <= "9";
  }

  insert(str, index, string) {
    if (index > 0) {
      return (
        str.substring(0, index) + string + str.substring(index, str.length)
      );
    }

    return string + str;
  }
  count(arr, el) {
    let cnt = 0;

    for (let i = 0; i < arr.length; ++i) {
      if (arr[i] === el) {
        cnt++;
      }
    }

    return cnt;
  }

  handleInputChange(str) {
    if (str === null) {
      str = this.state.input;
    }
    let button = 0;
    this.setState({ button: 0 });

    this.setState({ input: str });
    document.getElementById("data").value = str;
    str = str
      .replaceAll(" ", "")
      .replaceAll("|", "∨")
      .replaceAll("&", "∧")
      .replaceAll("==", "≡")
      .replaceAll("sin(", "!(")
      .replaceAll("cos(", "#(")
      .replaceAll("tan(", "№(")
      .replaceAll("cot(", "%(");

    if (str.indexOf("()") !== -1 || str.length === 0) {
      this.setState({ sentButtonColor: "big-bad-button" });
      this.setState({ normalizeInput: "" });
      return;
    }

    str = "(" + str + ")";
    let brackets = "";

    for (let i = 1; i < str.length - 1; ++i) {
      if (str[i] === "(" || str[i] === ")") {
        brackets += str[i];
      }
    }

    while (brackets.indexOf("()") !== -1) {
      brackets = brackets.replaceAll("()", "");
    }

    if (brackets.length !== 0) {
      this.setState({ sentButtonColor: "big-bad-button" });
      this.setState({ normalizeInput: "" });
      return;
    }

    let argument = "";

    let args = [];

    for (let i = 1; i < str.length; ++i) {
      if (str[i] === "(") {
        if (argument.length) {
          str = this.insert(str, i, String.fromCharCode(5));
          if (!this.isNumber(argument) && this.count(args, argument) === 0) {
            args.push(argument);
          }
        } else if (str[i - 1] === ")") {
          str = this.insert(str, i, String.fromCharCode(5));
        }

        argument = "";
      } else if (str[i] === ")") {
        if (!argument.length && str[i - 1] !== ")") {
          this.setState({ sentButtonColor: "big-bad-button" });
          this.setState({ normalizeInput: "" });

          return;
        }

        if (!this.isNumber(argument) && this.count(args, argument) === 0) {
          args.push(argument);
        }
        argument = "";
      } else if (this.isDigit(str[i])) {
        argument += str[i];
      } else if (this.isAlpha(str[i])) {
        if (this.isDigit(str[i - 1]) || str[i - 1] === ")") {
          str = this.insert(str, i++, String.fromCharCode(5));
          if (!this.isNumber(argument) && this.count(args, argument) === 0) {
            args.push(argument);
          }
          argument = "";
        }

        argument += str[i];
      } else if (this.operations.indexOf(str[i]) !== -1) {
        if (button === 0) {
          if (this.operations.indexOf(str[i]) <= 4) {
            button = 2;
          } else if (this.operations.indexOf(str[i]) <= 12) {
            button = 1;
          }
        } else if (button === 1) {
          if (this.operations.indexOf(str[i]) <= 4) {
            this.setState({ sentButtonColor: "big-bad-button" });
            this.setState({ normalizeInput: "" });
            return;
          }
        } else {
          if (
            this.operations.indexOf(str[i]) > 4 &&
            this.operations.indexOf(str[i]) <= 12
          ) {
            this.setState({ sentButtonColor: "big-bad-button" });
            this.setState({ normalizeInput: "" });
            return;
          }
        }
        if (
          !argument.length &&
          str[i - 1] !== ")" &&
          this.operations.indexOf(str[i]) !== 5 &&
          this.operations.indexOf(str[i]) <= 16
        ) {
          this.setState({ sentButtonColor: "big-bad-button" });
          this.setState({ normalizeInput: "" });

          return;
        }
        if (
          (str[i - 1] === ")" || argument.length) &&
          (this.operations.indexOf(str[i]) === 5 ||
            this.operations.indexOf(str[i]) > 16)
        ) {
          str = this.insert(str, i, String.fromCharCode(5));
        }

        if (
          argument.length &&
          !this.isNumber(argument) &&
          this.count(args, argument) === 0
        ) {
          args.push(argument);
        }

        argument = "";
      } else {
        this.setState({ sentButtonColor: "big-bad-button" });
        this.setState({ normalizeInput: "" });

        return;
      }
    }
    this.setState({ sentButtonColor: "big-good-button" });
    if (button === 0) {
      button = 2;
    }
    if (button === 1) {
      this.setState({
        normalizeInput: str
          .slice(1, str.length - 1)
          .replaceAll(String.fromCharCode(5), "∧"),
      });
    } else {
      this.setState({
        normalizeInput: str
          .slice(1, str.length - 1)
          .replaceAll(String.fromCharCode(5), "*"),
      });
    }
    this.setState({ button: button });
  }

  render() {
    let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

    return (
      <div className="wrapper">
        <HeadData
          title="Польская нотация – MathInstruments"
          url="https://MathInstruments.ru/PolishNotation"
          keys="MathInstruments, Main, MaIn, польская нотация, обратная польская запись, ПОЛИЗ, польская нотация, поиск ПОЛИЗ онлайн, онлайн, математические калькуляторы, калькуляторы, математические инструменты"
          description="На данной странице доступен функционал, позволяющий без труда по заданному выражению получить его польскую нотацию."
        />

        <Header updater={() => this.forceUpdate()} />

        <main className="content">
          <center id="input" className="form">
            <h2>Получение польских записей.</h2>

            <form onSubmit={this.handleSubmit}>
              <center className="my-border">
                <h3>Выражение:</h3>

                <input
                  id="data"
                  value={this.state.input}
                  className="form_control em315"
                  type="text"
                  placeholder="Введите выражение"
                  onChange={(e) => {
                    this.handleInputChange(e.target.value);
                  }}
                />
                <br />
                <div className="logic-operations-border mr1">
                  <h3>Арифметические операции:</h3>
                  <div className="mr1">
                    {arr.map((i) => {
                      if (i === 5) {
                        return (
                          <span key={i}>
                            <br />
                            <br />
                            <h3>Логические операции:</h3>
                            <button
                              className="previousAction"
                              type="button"
                              onClick={() => {
                                this.addOps(this.operations[i]);
                              }}
                            >
                              {this.operations[i]}
                            </button>
                          </span>
                        );
                      } else if (i === 13) {
                        return (
                          <span key={i}>
                            <br />
                            <br />
                            <h3>Тригонометрические операции:</h3>
                            <button
                              className="previousAction"
                              type="button"
                              onClick={() => {
                                this.addOps(this.operations[i]);
                              }}
                            >
                              {this.operations[i]}
                            </button>
                          </span>
                        );
                      } else if (i === 9) {
                        return (
                          <span key={i}>
                            <br />
                            <button
                              className="previousAction"
                              type="button"
                              onClick={() => {
                                this.addOps(this.operations[i]);
                              }}
                            >
                              {this.operations[i]}
                            </button>
                          </span>
                        );
                      }
                      return (
                        <button
                          className="previousAction"
                          key={i}
                          type="button"
                          onClick={() => {
                            this.addOps(this.operations[i]);
                          }}
                        >
                          {this.operations[i]}
                        </button>
                      );
                    })}
                  </div>
                </div>

                <button
                  id="result"
                  className={this.state.sentButtonColor}
                  type="submit"
                >
                  {"\u00A0"} Вычислить {"\u00A0"}
                </button>
              </center>

              <center>
                <Result value={this.state.result.replaceAll("_", " ")} />
              </center>

              <button
                className="clear-button"
                type="submit"
                onClick={() => {
                  this.setState({ button: 3 });
                }}
              >
                <svg viewBox="0 0 448 512" className="svgIcon">
                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                </svg>
              </button>

              <center>
                <PreviousActions
                  handleClick={this.setPreviousFormStates}
                  elementName="Polish"
                />
              </center>
            </form>
          </center>

          <div className="form pd05">
            <center>
              <h3 id="info">Инструкция:</h3>
            </center>
            <p>
              {" "}
              {"\u00A0"} {"\u00A0"} {"\u00A0"} {"\u00A0"} На данной странице
              пользователям предоставлен функционал, позволяющий найти польскую
              обратную для функций алгебры логики и арифметики. Вид переменной -
              это последовательность латинских символов и цифр, у которой первый
              символ всегда латинская буква. Недопустимой строкой является
              строка, где смешаны арифметические и логические операнды. Для
              получения ответа надо нажать кнопку "Вычислить"
            </p>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

export default PagePolishNotation;
