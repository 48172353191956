let DarkSelectTheme =
    (theme) => ({
        ...theme,
        borderWidth: "0px",
        colors: {
            ...theme.colors,
            primary25: '#4a4a4a',
            primary50: '#4a4a4a',
            primary75: '#4a4a4a',
            primary: '#222',
        },
    })

export default DarkSelectTheme;