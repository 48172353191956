import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import PageInstruments from './pages/PagesWithoutFunctionality/Instruments';
import PageAbout from './pages/PagesWithoutFunctionality/About';
import PageHome from './pages/PagesWithoutFunctionality/Home';
import PageContacts from './pages/PagesWithoutFunctionality/Contacts';
import PageNumberTranslator from './pages/NumberOperations/NumberTranslator';
import PageNumberOperations from './pages/NumberOperations/NumberOperations';
import PageNumberCoding from './pages/NumberOperations/NumberCoding';
import PageOneMatrixOperations from './pages/MatrixOperations/OneMatrixOperations';
import PageTwoMatrixOperations from './pages/MatrixOperations/TwoMatrixOperations'
import PageAlgebraOfLogic from './pages/DiscreteMath/AlgebraOfLogic'
import PageFunctionOfLogic from './pages/DiscreteMath/FunctionOfLogic'
import PageNokNod from './pages/Additional/NokNod';
import PagePow from './pages/Additional/Pow';
import PagePolishNotation from './pages/Additional/PolishNotation';

export default class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PageHome />} />
                    <Route path="/Instruments" element={<PageInstruments />} />
                    <Route path="/NumberTranslator" element={<PageNumberTranslator />} />
                    <Route path="/About" element={<PageAbout />} />
                    <Route path="/Contacts" element={<PageContacts />} />
                    <Route path="/NumberOperations" element={<PageNumberOperations />} />
                    <Route path="/NumberCoding" element={<PageNumberCoding />} />
                    <Route path="/OneMatrixOperations" element={<PageOneMatrixOperations />} />
                    <Route path="/TwoMatrixOperations" element={<PageTwoMatrixOperations />} />
                    <Route path="/AlgebraOfLogic" element={<PageAlgebraOfLogic />} />
                    <Route path="/FunctionOfLogic" element={<PageFunctionOfLogic />} />
                    <Route path="/NokNod" element={<PageNokNod />} />
                    <Route path="/Pow" element={<PagePow />} />
                    <Route path="/PolishNotation" element={<PagePolishNotation />} />
                </Routes>
            </BrowserRouter >
        );
    }
}