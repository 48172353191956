import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import FunctionOfLogicResult from "../../components/Results/FunctionOfLogicResult.js";
import HeadData from "../../components/HeadData/HeadData";

const TRANSLATOR_REST_URL = "https://math-instruments.ru:8443/FunctionOfLogic";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class FunctionOfLogic extends React.Component {
    operations = ['¬', '≡', '→', '⊕', '∨', '∧', '↓', '↑'];

    constructor(props) {
        super(props);

        this.state = {
            button: 1,
            input: "",
            normalizeInput: "",
            result: null,
            FirstButtonColor: "small-bad-button",
            SecondButtonColor: "small-bad-button",
            ThirdButtonColor: "small-bad-button",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(TRANSLATOR_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ input: response.data.input });
                this.setState({ normalizeInput: response.data.normalizeInput });
                this.setState({ result: response.data.result });
                this.handleInputChange(response.data.input);
            } else {
                this.setState({ FirstButtonColor: "big-bad-button" });
                this.setState({ SecondButtonColor: "big-bad-button" });
                this.setState({ ThirdButtonColor: "big-bad-button" });
            }

        })
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if ((this.state.FirstButtonColor === "big-bad-button" && this.state.button === 1) ||
            (this.state.SecondButtonColor === "big-bad-button" && this.state.button === 2) ||
            (this.state.ThirdButtonColor === "big-bad-button" && this.state.button === 3)) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        var formData = new FormData();
        formData.append('input', this.state.input);
        formData.append('normalizeInput', this.state.normalizeInput);
        formData.append('button', this.state.button);

        axios.post(TRANSLATOR_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ input: response.data.input });
                this.setState({ normalizeInput: response.data.normalizeInput });
                this.setState({ result: response.data.result });
                this.handleInputChange(response.data.input);
            } else {
                this.setState({ FirstButtonColor: "big-bad-button" });
                this.setState({ SecondButtonColor: "big-bad-button" });
                this.setState({ ThirdButtonColor: "big-bad-button" });
                this.setState({ result: "Введённое число содержит некорректные символы." });
            }
        })

        if (this.state.button !== 4) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }
    }

    setPreviousFormStates(e) {
        this.setState({ input: e.input });
        this.setState({ normalizeInput: e.normalizeInput });
        this.setState({ result: e.result });
        this.setState({ FirstButtonColor: "previousAction" });
        this.setState({ SecondButtonColor: "previousAction" });
        this.setState({ ThirdButtonColor: "previousAction" });

        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    isNumber(str) {
        if (str.length === 0) {
            return false;
        }

        for (let i = 0; i < str.length; ++i) {
            if (!this.isDigit(str[i])) {
                return false;
            }
        }

        return true;
    }

    isAlpha(ch) {
        return typeof ch === "string" && ch.length === 1 && ((ch >= "a" && ch <= "z") || (ch >= "A" && ch <= "Z"));
    }

    isDigit(c) {
        return typeof c === 'string' && c.length === 1 && c >= '0' && c <= '9';
    }

    insert(str, index, string) {
        if (index > 0) {
            return str.substring(0, index) + string + str.substring(index, str.length);
        }

        return string + str;
    };

    count(arr, el) {
        let cnt = 0;

        for (let i = 0; i < arr.length; ++i) {
            if (arr[i] === el) {
                cnt++;
            }
        }

        return cnt;
    }

    handleInputChange(str) {
        if (str === null) {
            str = this.state.input;
        }

        this.setState({ input: str });
        document.getElementById("data").value = str;
        str = str.replaceAll(" ", "").replaceAll("|", "∨").replaceAll("&", "∧").replaceAll("^", "⊕").replaceAll("==", "≡").replaceAll("-", "¬");

        if (str.indexOf("()") !== -1 || str.length === 0) {
            this.setState({ FirstButtonColor: "small-bad-button" });
            this.setState({ SecondButtonColor: "small-bad-button" });
            this.setState({ ThirdButtonColor: "small-bad-button" });
            this.setState({ normalizeInput: "" });
            return;
        }

        str = "(" + str + ")";
        let brackets = "";

        for (let i = 1; i < (str.length - 1); ++i) {
            if (str[i] === '(' || str[i] === ')') {
                brackets += str[i];
            }
        }

        while (brackets.indexOf("()") !== -1) {
            brackets = brackets.replaceAll("()", "");
        }

        if (brackets.length !== 0) {
            this.setState({ FirstButtonColor: "small-bad-button" });
            this.setState({ SecondButtonColor: "small-bad-button" });
            this.setState({ ThirdButtonColor: "small-bad-button" });
            this.setState({ normalizeInput: "" });
            return;
        }

        let argument = "";

        let args = [];

        for (let i = 1; i < str.length; ++i) {
            if (str[i] === '(') {
                if (argument.length) {
                    str = this.insert(str, i, "∧");
                    if (!this.isNumber(argument) && this.count(args, argument) === 0) {
                        args.push(argument);
                    }
                } else if (str[i - 1] === ')') {
                    str = this.insert(str, i, "∧");
                }

                argument = "";
            } else if (str[i] === ')') {
                if (!argument.length && str[i - 1] !== ')') {
                    this.setState({ FirstButtonColor: "small-bad-button" });
                    this.setState({ SecondButtonColor: "small-bad-button" });
                    this.setState({ ThirdButtonColor: "small-bad-button" });
                    this.setState({ normalizeInput: "" });

                    return;
                }

                if (!this.isNumber(argument) && this.count(args, argument) === 0) {
                    args.push(argument);
                }
                argument = "";
            } else if (this.isDigit(str[i])) {
                argument += str[i];
            } else if (this.isAlpha(str[i])) {
                if (this.isDigit(str[i - 1])) {
                    str = this.insert(str, i++, "∧");
                    if (!this.isNumber(argument) && this.count(args, argument) === 0) {
                        args.push(argument);
                    }
                    argument = "";
                }

                argument += str[i];
            } else if (this.operations.indexOf(str[i]) !== -1) {
                if (!argument.length && str[i - 1] !== ')' && this.operations.indexOf(str[i]) !== 0) {
                    this.setState({ FirstButtonColor: "small-bad-button" });
                    this.setState({ SecondButtonColor: "small-bad-button" });
                    this.setState({ ThirdButtonColor: "small-bad-button" });
                    this.setState({ normalizeInput: "" });

                    return;
                }

                if ((str[i - 1] === ')' || argument.length) && this.operations.indexOf(str[i]) === 5) {
                    str = this.insert(str, i, "∧");
                }

                if (argument.length && !this.isNumber(argument) && this.count(args, argument) === 0) {
                    args.push(argument);
                }

                argument = "";
            } else {
                this.setState({ FirstButtonColor: "small-bad-button" });
                this.setState({ SecondButtonColor: "small-bad-button" });
                this.setState({ ThirdButtonColor: "small-bad-button" });
                this.setState({ normalizeInput: "" });

                return;
            }
        }

        this.setState({ FirstButtonColor: "previousAction" });
        this.setState({ SecondButtonColor: "previousAction" });
        this.setState({ ThirdButtonColor: "previousAction" });
        this.setState({ normalizeInput: str.slice(1, str.length - 1) });
    }

    getSelectionStart(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveEnd('character', o.value.length)
            if (r.text === '') return o.value.length
            return o.value.lastIndexOf(r.text)
        } else return o.selectionStart
    }

    getSelectionEnd(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveStart('character', -o.value.length)
            return r.text.length
        } else return o.selectionEnd
    }

    addOps(op) {
        let elem = document.getElementById("data");
        let s = this.getSelectionStart(elem), e = this.getSelectionEnd(elem);
        let str = elem.value;
        str = str.slice(0, s) + op + str.slice(e);

        this.handleInputChange(str, null);

        elem.focus();
        elem.setSelectionRange(s + 1, s + 1);
    }

    render() {
        let arr = [0, 1, 2, 3, 4, 5, 6, 7];

        return (
            <div className="wrapper">
                <HeadData
                    title="Полином Жегалкина, СКНФ и СДНФ – MathInstruments"
                    url="https://MathInstruments.ru/FunctionOfLogic"
                    keys="MathInstruments, Main, MaIn, полином Жегалкина, полином жегалкина, жигалкин, жегалкин, полином, полином жегалкина онлайн, онлайн, СКНФ, СДНФ, скнф, сднф, подсчёт скнф, подсчёт сднф, математические калькуляторы, калькуляторы, математические инструменты"
                    description="На данной странице мы предлагаем пользователям функционал, позволяющий по заданному логическому выражнию быстро вычислять полином Жегалкина, СКНФ и СДНФ."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form">
                        <h2>Подсчёт полинома Жегалкина, СКНФ и СДНФ.</h2>
                        <form onSubmit={this.handleSubmit}>
                            <center className="my-border">
                                <h3>Логическое выражение:</h3>
                                <input id="data" value={this.state.input} className="form_control em315" type="text" placeholder="Введите логическое выражение" onChange={(e) => { this.handleInputChange(e.target.value); }} />
                                <br />
                                <div className="logic-operations-border mr1">
                                    <h3>Операции:</h3>
                                    <div className="mr1">
                                        {
                                            arr.map((i) => {
                                                if (i % 4) {
                                                    return (<button className="previousAction" key={i} type="button" onClick={() => { this.addOps(this.operations[i]) }}>{this.operations[i]}</button>);
                                                } else {
                                                    return (<span key={i}><br /><button className="previousAction" type="button" onClick={() => { this.addOps(this.operations[i]) }}>{this.operations[i]}</button></span>);
                                                }
                                            })
                                        }
                                    </div>
                                </div>

                                <center>
                                    <button className={this.state.FirstButtonColor} type="submit" onClick={() => { this.setState({ button: 1 }) }}>{'\u00A0'} Вычислить полином жегалкина {'\u00A0'}</button>
                                    <br />
                                    <button className={this.state.SecondButtonColor} type="submit" onClick={() => { this.setState({ button: 2 }) }}>{'\u00A0'} Вычислить СКНФ {'\u00A0'}</button>
                                    <br />
                                    <button id="result" className={this.state.ThirdButtonColor} type="submit" onClick={() => { this.setState({ button: 3 }) }}>{'\u00A0'} Вычислить СДНФ {'\u00A0'}</button>
                                </center>
                            </center>

                            <center>
                                <FunctionOfLogicResult result={this.state.result} operation={this.state.button} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => { this.setState({ button: 4 }); }}><svg viewBox="0 0 448 512" className="svgIcon"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg></button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates} elementName="FunctionOperation" /></center>
                        </form>
                    </center>

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по поиску полинома Жегалкина, СКНФ и СДНФ:</h3></center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям доступен функционал, позволяющий по заданному логическому выражению получить полином Жегалкина, СКНФ и СДНФ. Для того, чтобы произвести вычисления достаточно указать в поле для ввода логическое выражение, которое может содержать только целые неотрицательные числа, переменные и операции, переменная может состоять только из латинских букв и арабских цифр, причём она может содержать заглавные буквы и незначащие нули, но не может начинаться с цифры! После того, как пользователь ввёл выражение, достаточно нажать на кнопку, соответствующую нужной операции и результат будет выведен на экран.</p>
                    </div>
                </main >

                <Footer />
            </div >
        );
    }
}

export default FunctionOfLogic;
