import Swal from "sweetalert2";

let flag = false;

let LightAlert = {
    icon: "error",
    title: "Что-то пошло не так...",
    html: `<a class="crestik" id="crestik">×</a>Проверьте введённые данные на соответсвие требованиям!</br> <button class="previousAction alert-button" id="close-alert" type="button">Перейти к требованиям</button></br><a id="error-link" href="/Contacts">Сообщить об ошибке</a>`,
    background: "#ffffff",
    showConfirmButton: false,
    color: "#5a779e",

    didOpen: () => {
        document.getElementById("crestik").addEventListener("click", () => { Swal.close(); });
        document.getElementById("close-alert").addEventListener("click", () => { flag = true; Swal.close(); });
    },

    didClose: () => {
        if (flag) {
            flag = false;
            document.getElementById("info").scrollIntoView();
        }
    },
}

export default LightAlert;