import axios from "axios";
import React from "react";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Header from "../../components/GeneralItems/Header.js";
import Footer from "../../components/GeneralItems/Footer.js";
import PreviousActions from "../../components/GeneralItems/PreviousActions.js";
import LightAlert from "../../components/Alert/LightAlert.js";
import DarkAlert from "../../components/Alert/DarkAlert.js";
import SelectBlock from "../../components/Select/SelectBlock.js";
import Matrix from "../../components/Matrix/MatrixCreator.js";
import ResultMatrix from "../../components/Results/ResultMatrixCreator.js";
import ResultDeterminant from "../../components/Results/ResultDeterminant.js";
import HeadData from "../../components/HeadData/HeadData";

const MATRIX_OPERATIONS_REST_URL = "https://math-instruments.ru:8443/TwoMatrixOperations";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

class PageTwoMatrixOperations extends React.Component {
    normalizeString(str) {
        while (str[0] === " ") {
            str = str.slice(1);
        }
        while (str[str.length - 1] === " ") {
            str = str.slice(0, str.length - 1);
        }
        return str;
    }

    constructor(props) {
        super(props);

        this.state = {
            button: 1,
            matrix1: <Matrix matrix={[[0, 0, 0], [0, 0, 0], [0, 0, 0]]} matrixId="matrixEl1" width1={3} width2={3}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />,
            matrix2: <Matrix matrix={[[0, 0, 0], [0, 0, 0], [0, 0, 0]]} matrixId="matrixEl2" width1={3} width2={3}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />,
            resultMatrix: <ResultMatrix matrix={[[]]} />,
            matrixHeight1: 3,
            matrixWidth1: 3,
            matrixHeight2: 3,
            matrixWidth2: 3,
            operation: '+',
            sentButtonColor: "big-good-button",
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateMatrix1 = this.updateMatrix1.bind(this);
        this.updateMatrix2 = this.updateMatrix2.bind(this);
        this.setPreviousFormStates = this.setPreviousFormStates.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const inputs = document.querySelectorAll('input');

        inputs.forEach(input => {
            input.setAttribute('autocomplete', 'off')
            input.setAttribute('autocorrect', 'off')
            input.setAttribute('autocapitalize', 'off')
            input.setAttribute('spellcheck', false)
        })

        axios.get(MATRIX_OPERATIONS_REST_URL, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({
                    matrix1: <Matrix matrix={response.data.matrix1} matrixId="matrixEl1"
                        width1={response.data.matrixWidth1} width2={response.data.matrixWidth2}
                        onChange={() => {
                            this.handleInputChange(null, null, null);
                        }} />
                });
                this.setState({
                    matrix2: <Matrix matrix={response.data.matrix2} matrixId="matrixEl2"
                        width1={response.data.matrixWidth1} width2={response.data.matrixWidth2}
                        onChange={() => {
                            this.handleInputChange(null, null, null);
                        }} />
                });
                this.setState({ resultMatrix: <ResultMatrix matrix={response.data.resultMatrix} /> });
                this.setState({ matrixHeight1: response.data.matrixHeight1 });
                this.setState({ matrixWidth1: response.data.matrixWidth1 });
                this.setState({ matrixHeight2: response.data.matrixHeight2 });
                this.setState({ matrixWidth2: response.data.matrixWidth2 });

                let ops = ["+", "-", "*", "+"];
                this.setState({ operation: ops[response.data.button - 1] });
            }
            this.handleInputChange(response.data.matrix, response.data.matrixPow, response.data.matrixFactor);
        })
    }

    setPreviousFormStates(e) {
        this.setState({
            matrix1: <Matrix matrix={e.matrix1} matrixId="matrixEl1" width1={e.matrixWidth1} width2={e.matrixWidth2}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
        this.setState({
            matrix2: <Matrix matrix={e.matrix2} matrixId="matrixEl2" width1={e.matrixWidth1} width2={e.matrixWidth2}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
        this.setState({ resultMatrix: <ResultMatrix matrix={e.resultMatrix} /> });
        this.setState({ matrixHeight1: e.matrixHeight1 });
        this.setState({ matrixWidth1: e.matrixWidth1 });
        this.setState({ matrixHeight2: e.matrixHeight2 });
        this.setState({ matrixWidth2: e.matrixWidth2 });
        if (e.button === 1) {
            this.setState({ operation: '+' });
        } else if (e.button === 2) {
            this.setState({ operation: '-' });
        } else {
            this.setState({ operation: '*' });
        }
        this.setState({ sentButtonColor: "big-good-button" });

        document.getElementById("input").scrollIntoView({ behavior: "smooth" });
    }

    handleSubmit = async (props) => {
        props.preventDefault();

        if (this.state.sentButtonColor === "big-bad-button" && !(this.state.button === 4)) {
            if (Cookies.get("themeColor") === "dark") {
                Swal.fire(DarkAlert);
            } else {
                Swal.fire(LightAlert);
            }

            return;
        }

        const formData = new FormData();
        formData.append('matrixHeight1', this.state.matrixHeight1);
        formData.append('matrixWidth1', this.state.matrixWidth1);
        formData.append('matrixHeight2', this.state.matrixHeight2);
        formData.append('matrixWidth2', this.state.matrixWidth2);
        if (this.state.button === 4) {
            formData.append('button', this.state.button);
        } else if (this.state.operation === '*') {
            formData.append('button', 3);
        } else if (this.state.operation === '-') {
            formData.append('button', 2);
        } else if (this.state.operation === '+') {
            formData.append('button', 1);
        }

        for (let i = 0; i < this.state.matrixHeight1; ++i) {
            var subArr = [];
            for (let j = 0; j < this.state.matrixWidth1; ++j) {
                subArr.push(this.normalizeString(document.getElementById('matrixEl1' + i + j).value));
            }
            formData.append('matrix1', subArr);

        }
        for (let i = 0; i < this.state.matrixHeight2; ++i) {
            subArr = [];
            for (let j = 0; j < this.state.matrixWidth2; ++j) {
                subArr.push(this.normalizeString(document.getElementById('matrixEl2' + i + j).value));
            }
            formData.append('matrix2', subArr);
        }

        axios.post(MATRIX_OPERATIONS_REST_URL, formData, { withCredentials: true }).then((response) => {
            if (!response.data.msg) {
                this.setState({ msg: "" });
                this.setState({
                    matrix1: <Matrix matrix={response.data.matrix1} matrixId="matrixEl1"
                        width1={response.data.matrixWidth1} width2={response.data.matrixWidth2}
                        onChange={() => {
                            this.handleInputChange(null, null, null);
                        }} />
                });
                this.setState({
                    matrix2: <Matrix matrix={response.data.matrix2} matrixId="matrixEl2"
                        width1={response.data.matrixWidth1} width2={response.data.matrixWidth2}
                        onChange={() => {
                            this.handleInputChange(null, null, null);
                        }} />
                });
                this.setState({ resultMatrix: <ResultMatrix matrix={response.data.resultMatrix} /> });
                this.setState({ matrixHeight1: response.data.matrixHeight1 });
                this.setState({ matrixWidth1: response.data.matrixWidth1 });
                this.setState({ matrixHeight2: response.data.matrixHeight2 });
                this.setState({ matrixWidth2: response.data.matrixWidth2 });
                this.setState({ sentButtonColor: "big-good-button" });
            } else {
                this.setState({ msg: "Размер матрицы не подходит для подсчета выражения, либо данные матрицы введены неверно." });
                this.setState({ sentButtonColor: "big-bad-button" });
            }
        })

        if (this.state.button !== 4) {
            document.getElementById("result").scrollIntoView({ behavior: "smooth" });
        } else {
            document.getElementById("input").scrollIntoView({ behavior: "smooth" });
            await delay(350);
        }
    }

    updateMatrix1(e, isHeight) {
        let matrixHeight = this.state.matrixHeight1;
        let matrixWidth = this.state.matrixWidth1;

        if (e) {
            if (isHeight) {
                matrixHeight = e.value;
            } else {
                matrixWidth = e.value;
            }
        }

        var arr = [];

        for (let i = 0; i < this.state.matrixHeight1; ++i) {
            var subArr = [];
            for (let j = 0; j < this.state.matrixWidth1; ++j) {
                subArr.push(document.getElementById('matrixEl1' + i + j).value);
            }
            arr.push(subArr);
        }

        this.setState({ msg: "" });
        this.setState({ matrixWidth1: matrixWidth });
        this.setState({ matrixHeight1: matrixHeight });

        var res = [];

        for (let i = 0; i < matrixHeight; ++i) {
            var line = [];
            for (let j = 0; j < matrixWidth; ++j) {
                line.push("0");
            }
            res.push(line);
        }

        var arr2 = [];

        for (let i = 0; i < this.state.matrixHeight2; ++i) {
            subArr = [];
            for (let j = 0; j < this.state.matrixWidth2; ++j) {
                subArr.push(document.getElementById('matrixEl2' + i + j).value);
            }
            arr2.push(subArr);
        }

        this.handleInputChange(res, null, null);

        this.setState({
            matrix1: <Matrix matrix={res} matrixId="matrixEl1" width1={res[0].length} width2={this.state.matrixWidth2}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
        this.setState({
            matrix2: <Matrix matrix={arr2} matrixId="matrixEl2" width1={res[0].length} width2={this.state.matrixWidth2}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
    }

    updateMatrix2(e, isHeight) {
        let matrixHeight = this.state.matrixHeight2;
        let matrixWidth = this.state.matrixWidth2;

        if (e) {
            if (isHeight) {
                matrixHeight = e.value;
            } else {
                matrixWidth = e.value;
            }
        }

        var arr = [];

        for (let i = 0; i < this.state.matrixHeight2; ++i) {
            var subArr = [];
            for (let j = 0; j < this.state.matrixWidth2; ++j) {
                subArr.push(document.getElementById('matrixEl2' + i + j).value);
            }
            arr.push(subArr);
        }

        this.setState({ msg: "" });
        this.setState({ matrixWidth2: matrixWidth });
        this.setState({ matrixHeight2: matrixHeight });

        var res = [];

        for (let i = 0; i < matrixHeight; ++i) {
            var line = [];
            for (let j = 0; j < matrixWidth; ++j) {
                line.push("0");
            }
            res.push(line);
        }

        var arr1 = [];

        for (let i = 0; i < this.state.matrixHeight1; ++i) {
            subArr = [];
            for (let j = 0; j < this.state.matrixWidth1; ++j) {
                subArr.push(document.getElementById('matrixEl1' + i + j).value);
            }
            arr1.push(subArr);
        }

        this.handleInputChange(null, res, null);

        this.setState({
            matrix2: <Matrix matrix={res} matrixId="matrixEl2" width2={res[0].length} width1={this.state.matrixWidth1}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
        this.setState({
            matrix1: <Matrix matrix={arr1} matrixId="matrixEl1" width2={res[0].length} width1={this.state.matrixWidth1}
                onChange={() => {
                    this.handleInputChange(null, null, null);
                }} />
        });
    }

    isNumber(str) {
        let base = 10;
        str = str.toLowerCase().replaceAll(',', '.');
        let template = "+-.0123456789abcdef";

        if ((str.split(".").length - 1) > 1 || (str.split("+").length - 1) > 1 || (str.split("-").length - 1) > 1 || str.length === 0) {
            return false;
        }

        for (let i = 0; i < str.length; ++i) {
            if ((str[i] === '+' || str[i] === '-')) {
                if (i !== 0 || str.length === 1) {
                    return false;
                }
            } else if (str[i] === '.') {
                if (i === 0 || i === (str.length - 1) || (((str[0] === '+') || (str[0] === '-')) && i === 1)) {
                    return false;
                }
            } else {
                if (template.indexOf(str[i]) === -1 || (template.indexOf(str[i]) - 3) >= base) {
                    return false;
                }
            }
        }

        return true;
    }

    handleInputChange(arr1, arr2, operation) {
        if (arr1 === null || arr1 === undefined) {
            arr1 = [];

            for (let i = 0; i < this.state.matrixHeight1; ++i) {
                var subArr = [];
                for (let j = 0; j < this.state.matrixWidth1; ++j) {
                    subArr.push(this.normalizeString(document.getElementById('matrixEl1' + i + j).value));
                }
                arr1.push(subArr);
            }
        }

        let badMatrix = false;

        for (let i = 0; i < arr1.length; ++i) {
            for (let j = 0; j < arr1[i].length; ++j) {
                if (!this.isNumber(arr1[i][j])) {
                    badMatrix = true;
                }
            }
        }

        if (arr2 === null || arr2 === undefined) {
            arr2 = [];

            for (let i = 0; i < this.state.matrixHeight2; ++i) {
                subArr = [];
                for (let j = 0; j < this.state.matrixWidth2; ++j) {
                    subArr.push(this.normalizeString(document.getElementById('matrixEl2' + i + j).value));
                }
                arr2.push(subArr);
            }
        }

        for (let i = 0; i < arr2.length; ++i) {
            for (let j = 0; j < arr2[i].length; ++j) {
                if (!this.isNumber(arr2[i][j])) {
                    badMatrix = true;
                }
            }
        }

        let op = operation;

        if (op === null) {
            op = this.state.operation;
        }

        if (badMatrix) {
            this.setState({ sentButtonColor: "big-bad-button" });
        } else {
            if (op === '*') {
                if (arr1[0].length === arr2.length) {
                    this.setState({ sentButtonColor: "big-good-button" });
                } else {
                    this.setState({ sentButtonColor: "big-bad-button" });
                }
            } else {
                if (arr1.length === arr2.length && arr1[0].length === arr2[0].length) {
                    this.setState({ sentButtonColor: "big-good-button" });
                } else {
                    this.setState({ sentButtonColor: "big-bad-button" });
                }
            }
        }
    }

    render() {
        let size = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }, { value: 4, label: 4 }, {
            value: 5,
            label: 5
        }];
        let ops = [{ value: '+', label: '+' }, { value: '-', label: '-' }, { value: '*', label: '*' }];

        return (
            <div className="wrapper">
                <HeadData
                    title="Умножение, сложение и вычитание матриц – MathInstruments"
                    url="https://MathInstruments.ru/TwoMatrixOperations"
                    keys="MathInstruments, Main, MaIn, умножение матриц, сложение матриц, вычитание матриц, арифметические операции над матрицами онлайн, онлайн, математические калькуляторы, калькуляторы, математические инструменты"
                    description="На данной странице мы предлагаем пользователям функционал, позволяющий быстро умножать, вычитать и складывать вещественные матрицы."
                />

                <Header updater={() => this.forceUpdate()} />

                <main className="content">
                    <center id="input" className="form twoMatrixForm">
                        <h2>Операции с вещественными матрицами.</h2>
                        <form onSubmit={this.handleSubmit} action="/twoMatrixOperations" method="post">
                            <center className="TM my-border">
                                <div className="operands">
                                    <div className="operand">
                                        {/* <div className="operand"> */}
                                        <label>Укажите размерность первой матрицы.</label>
                                        <br />
                                        <div className='select-mini inline-block'>
                                            <SelectBlock onChange={(e) => {
                                                this.updateMatrix1(e, true);
                                            }} options={size} value={{
                                                value: this.state.matrixHeight1,
                                                label: this.state.matrixHeight1
                                            }} />
                                        </div>
                                        <span className="multi-symbol">×</span>
                                        <div className='select-mini inline-block'>
                                            <SelectBlock onChange={(e) => {
                                                this.updateMatrix1(e, false);
                                            }} options={size} value={{
                                                value: this.state.matrixWidth1,
                                                label: this.state.matrixWidth1
                                            }} />
                                        </div>
                                        <br />
                                        <br />
                                        {this.state.matrix1}
                                    </div>
                                    {(this.state.matrixWidth1 + this.state.matrixWidth2) >= 8 ? <br /> : <></>}

                                    <div className="operand mr">
                                        {/* <div className="operand mr"> */}
                                        <div className="sub-my-border">
                                            <label>Операция</label>
                                            <div className='select-mini'>
                                                <SelectBlock onChange={(e) => {
                                                    this.setState({ operation: e.value });
                                                    this.handleInputChange(null, null, e.value);
                                                }} options={ops} value={{
                                                    value: this.state.operation,
                                                    label: this.state.operation
                                                }} />
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.matrixWidth1 + this.state.matrixWidth2) >= 8 ? <br /> : <></>}

                                    <div className="operand">
                                        {/* <div className="operand operand-border"> */}
                                        <label>Укажите размерность второй матрицы.</label>
                                        <br />
                                        <div className='select-mini inline-block'>
                                            <SelectBlock onChange={(e) => {
                                                this.updateMatrix2(e, true);
                                            }} options={size} value={{
                                                value: this.state.matrixHeight2,
                                                label: this.state.matrixHeight2
                                            }} />
                                        </div>
                                        <span className="multi-symbol">×</span>
                                        <div className='select-mini inline-block'>
                                            <SelectBlock onChange={(e) => {
                                                this.updateMatrix2(e, false);
                                            }} options={size} value={{
                                                value: this.state.matrixWidth2,
                                                label: this.state.matrixWidth2
                                            }} />
                                        </div>
                                        <br />
                                        <br />
                                        {this.state.matrix2}
                                    </div>
                                </div>

                                <br />
                                <button id="result" className={this.state.sentButtonColor} type="submit" onClick={() => {
                                    this.setState({ button: 1 })
                                }}>{'\u00A0'} Вычислить {'\u00A0'}</button>
                            </center>

                            <center>
                                <ResultDeterminant determinant="" resultMatrix={this.state.resultMatrix} />
                            </center>

                            <button className="clear-button" type="submit" onClick={() => {
                                this.setState({ button: 4 });
                            }}>
                                <svg viewBox="0 0 448 512" className="svgIcon">
                                    <path
                                        d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                                </svg>
                            </button>

                            <center><PreviousActions handleClick={this.setPreviousFormStates}
                                elementName="TwoMatrixOperation" /></center>
                        </form>
                    </center>

                    <div className="form pd05">
                        <center><h3 id="info">Инструкция по работе с операциями над вещественными матрицами:</h3>
                        </center>
                        <p> {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} На данной странице пользователям предоставлен
                            функционал, позволяющий просто считать сумму, разность и произведение вещественных матриц. В
                            ячейку матрицы может быть записано вещественное число, состоящее только из арабских цифр,
                            символов "+" и "-" для смены знака(не обязательно), а также ".", "," для разделения дробной
                            и вещественной частей элемента матрицы. Чтобы выполнить операцию, достаточно задать в
                            соответствующие поля размерности матриц(при этом необходимо учитывать, что некоторые операции не могут быть произведены со всеми размерностями матриц), затем ввести значения элементов матриц, после чего
                            выбрать нужное вам действие и нажать на кнопку с надписью "Вычислить".</p>
                    </div>
                </main>

                <Footer />
            </div>
        );
    }

}

export default PageTwoMatrixOperations;
