// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default height for small devices */
#intro-example {
  height: 400px;
}

/* Height for devices larger than 992px */
@media (min-width: 992px) {
  #intro-example {
    height: 600px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/General/Header.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,aAAa;AACf;;AAEA,yCAAyC;AACzC;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* Default height for small devices */\n#intro-example {\n  height: 400px;\n}\n\n/* Height for devices larger than 992px */\n@media (min-width: 992px) {\n  #intro-example {\n    height: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
