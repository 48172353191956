import React from "react";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="inner footer-inner">
                    <nav>
                        <a href="/" className="active">Домашняя страница</a>
                        <a href="/Instruments">Инструменты</a>
                        <a href="/Contacts">Контакты</a>
                        <a href="/About">О нас</a>
                    </nav>
                </div>
                <center><center className="pstcr">2024-2024 © <a href="/">MathInstruments.ru</a> - инструменты для вычисления математических выражений для всех</center></center>
            </footer>
        );
    }
}

export default Footer;