import React from "react";

class Result extends React.Component {
    render() {
        if (this.props.value) {
            return (
                <div className="my-border">
                    <h2>Результат:</h2>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.value}</output>
                </div >
            )
        }

        return (
            <></>
        )
    }
}

export default Result;