import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import ThemeSwitcher from "./ThemeSwitcher";

const StyledHeader = styled.header`
  width: 100%;
  padding: 10px 12px 8px 12px;
  display: flex;
  align-items: center;
  .nav_logo {
    padding: 0 12px;
    .nav-logo-link {
      text-decoration: none;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .menuToggleBtn {
    z-index: 2;
    right: 6em;
    display: none;
    font-size: 18px;
    position: absolute;
    top: 0.75em;
    vertical-align: middle;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .menuToggleBtn {
      display: block;
    }
  }
`;
const NavManu = styled.ul`
  list-style: none;
  display: flex;

  li {
    a {
      text-decoration: none;
    }
    &:hover {
      cursor: pointer;
      border-radius: 4px;
    }
  }
  .nav-menu-list {
    text-decoration: none;
    display: block;
    padding: 3px 10px;
  }
  @media screen and (max-width: 768px) {
    .hidden-menu {
      display: none !important;
    }
    position: relative;
    display: ${(props) => (props.ito === "true" ? "block" : "none")};
    border-top: ${(props) => (props.ito === "true" ? "1px solid" : "none")};
    flex-direction: column;
    align-items: center;
    height: min-content;
    width: 100%;
    margin-top: 5px;
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ito: false,
    };
    this.handleToggleOpen = this.handleToggleOpen.bind(this);
  }

  handleToggleOpen = () => {
    this.setState({ ito: !this.state.ito });
  };

  render() {
    return (
      <>
        <span id="snackbar">Обращение успешно отправлено!</span>
        <StyledHeader>
          <Link to={"/"} className="nav-logo-link">
            <svg className="logo-size" version="1.1" width="128" height="50" viewBox="0 0 2000 873">
              <g transform="matrix(1,0,0,1,-1.2121212121212466,0.08509621967823477)">
                <svg viewBox="0 0 396 173" data-background-color="#ffffff" preserveAspectRatio="xMidYMid meet" height="873" width="2000">
                  <g id="tight-bounds" transform="matrix(1,0,0,1,0.2400000000000091,-0.016863282937379154)">
                    <svg viewBox="0 0 395.52 173.03372656587476" height="173.03372656587476" width="395.52">
                      <g>
                        <svg viewBox="0 0 395.52 173.03372656587476" height="173.03372656587476" width="395.52">
                          <g>
                            <svg viewBox="0 0 395.52 173.03372656587476" height="173.03372656587476" width="395.52">
                              <g id="textblocktransform">
                                <svg viewBox="0 0 395.52 173.03372656587476" height="173.03372656587476" width="395.52" id="textblock">
                                  <g>
                                    <svg viewBox="0 0 395.52 173.03372656587476" height="173.03372656587476" width="395.52">
                                      <rect width="395.52" height="173.03372656587476" x="0" y="0" opacity="1" fill="transparent" data-fill-palette-color="tertiary">
                                      </rect>
                                      <g transform="matrix(1,0,0,1,23.607599999999998,23.607599999999998)">
                                        <svg width="348.3048" viewBox="4.6 -32.85 92.59 33.45" height="125.81852656587475" data-palette-color="#5a779e">
                                          <path d="M7.95 0L4.6 0 4.6-32.85 9.15-32.85 15.6-14.85Q16.2-13.15 16.8-11.4 17.4-9.65 18-7.9L18-7.9 18.2-7.9Q18.8-9.65 19.38-11.4 19.95-13.15 20.55-14.85L20.55-14.85 26.9-32.85 31.5-32.85 31.5 0 28.05 0 28.05-19Q28.05-20.4 28.13-22.05 28.2-23.7 28.3-25.38 28.4-27.05 28.5-28.5L28.5-28.5 28.3-28.5 25.7-21.05 19.3-3.4 16.7-3.4 10.25-21.05 7.65-28.5 7.45-28.5Q7.55-27.05 7.68-25.38 7.8-23.7 7.88-22.05 7.95-20.4 7.95-19L7.95-19 7.95 0ZM45.8 0.6L45.8 0.6Q43.8 0.6 42.2-0.2 40.6-1 39.65-2.53 38.7-4.05 38.7-6.2L38.7-6.2Q38.7-10.2 42.32-12.33 45.95-14.45 53.8-15.3L53.8-15.3Q53.8-16.9 53.37-18.43 52.95-19.95 51.82-20.88 50.7-21.8 48.6-21.8L48.6-21.8Q46.4-21.8 44.52-20.95 42.65-20.1 41.25-19.15L41.25-19.15 39.8-21.65Q40.85-22.35 42.3-23.1 43.75-23.85 45.5-24.35 47.25-24.85 49.2-24.85L49.2-24.85Q52.1-24.85 53.92-23.6 55.75-22.35 56.57-20.15 57.4-17.95 57.4-15L57.4-15 57.4 0 54.4 0 54.1-2.95 53.95-2.95Q52.2-1.5 50.15-0.45 48.1 0.6 45.8 0.6ZM46.75-2.35L46.75-2.35Q48.6-2.35 50.25-3.23 51.9-4.1 53.8-5.75L53.8-5.75 53.8-12.85Q49.5-12.35 47-11.48 44.5-10.6 43.4-9.35 42.3-8.1 42.3-6.45L42.3-6.45Q42.3-4.3 43.62-3.33 44.95-2.35 46.75-2.35ZM69.4 0L65.7 0 65.7-32.85 69.4-32.85 69.4 0ZM81.79 0L78.19 0 78.19-24.25 81.19-24.25 81.49-20.65 81.64-20.65Q83.39-22.45 85.37-23.65 87.34-24.85 89.79-24.85L89.79-24.85Q93.64-24.85 95.42-22.45 97.19-20.05 97.19-15.4L97.19-15.4 97.19 0 93.54 0 93.54-14.95Q93.54-18.45 92.42-20.05 91.29-21.65 88.74-21.65L88.74-21.65Q86.79-21.65 85.27-20.68 83.74-19.7 81.79-17.75L81.79-17.75 81.79 0Z" opacity="1" transform="matrix(1,0,0,1,0,0)" className="wordmark-text-0 logo-text" data-fill-palette-color="quaternary" id="text-0">
                                          </path>
                                        </svg>
                                      </g>
                                    </svg>
                                  </g>
                                </svg>
                              </g>
                            </svg>
                          </g>
                          <g></g>
                        </svg>
                      </g>
                      <defs></defs>
                    </svg>
                    <rect width="395.52" height="173.03372656587476" fill="none" stroke="none" visibility="hidden">
                    </rect>
                  </g>
                </svg>
              </g>
            </svg>
          </Link>


          <NavManu ito={this.state.ito.toString()}>
            <li className="nav-menu-list">
              <Link to="/" className="active">
                Домашняя страница
              </Link>
            </li>
            <li className="nav-menu-list">
              <span className="smart-ul">
                <Link to="/Instruments">
                  Инструменты
                </Link>
                <div className="hidden-menu">
                  <Link to="/Instruments#ECS"><span className="my-bold">Системы счисления и кодировки:</span></Link>
                  <a href="/NumberTranslator">{'\u00A0'}{'\u00A0'}Перевод чисел в различные сс</a>
                  <a href="/NumberOperations">{'\u00A0'}{'\u00A0'}Арифметика в различных сс</a>
                  <a href="/NumberCoding">{'\u00A0'}{'\u00A0'}Обратный и дополнительный коды</a>
                  <Link to="/Instruments#matrix"><span className="my-bold">Матрицы:</span></Link>
                  <a href="/OneMatrixOperations">{'\u00A0'}{'\u00A0'}Операции над одной матрицей</a>
                  <a href="/TwoMatrixOperations">{'\u00A0'}{'\u00A0'}Операции над двумя матрицами</a>
                  <Link to="/Instruments#DM"><span className="my-bold">Дискретная математика:</span></Link>
                  <a href="/AlgebraOfLogic">{'\u00A0'}{'\u00A0'}Алгебра логики</a>
                  <a href="/FunctionOfLogic">{'\u00A0'}{'\u00A0'}Полином Жегалкина, СДНФ и СДНФ</a>
                  <Link to="/Instruments#Additional"><span className="my-bold">Вспомогательные функции:</span></Link>
                  <a href="/NokNod">{'\u00A0'}{'\u00A0'}Поиск НОК и НОД</a>
                  <a href="/Pow">{'\u00A0'}{'\u00A0'}Степени чисел</a>
                  <a href="/PolishNotation" className="hidden-offset">{'\u00A0'}{'\u00A0'}Польская запись</a>
                </div>
              </span>
            </li>
            <li className="nav-menu-list">
              <Link to={"/Contacts"}>
                Контакты
              </Link>
            </li>
            <li className="nav-menu-list">
              <Link to={"/About"}>
                О нас
              </Link>
            </li>
          </NavManu>
          <div className="menuToggleBtn"><FaBars onClick={() => { this.handleToggleOpen() }} /></div>

          <ThemeSwitcher updater={this.props.updater} />
        </StyledHeader>
      </>
    );
  }
};

export default Header;
