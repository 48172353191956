import React from "react";
import Cookies from 'js-cookie';

class PreviousActions extends React.Component {
    render() {
        let arr = [];
        let title = <></>;

        for (let i = 1; i <= 5; ++i) {
            let cookie = Cookies.get(this.props.elementName + "-" + i);
            if (!cookie) {
                break;
            }

            title = <h4>Предыдущие успешные действия, которыми можно заполнить форму:</h4>;
            
            cookie = decodeURIComponent(atob(cookie).split('').map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));

            let previousAction = JSON.parse(cookie);
            let btnName = "";

            switch (this.props.elementName) {
                case ("Translation"):
                    btnName =
                        <>
                            {previousAction.number}
                            <sub>{previousAction.baseFrom}</sub>
                            {" переведено в "}
                            {previousAction.result}
                            <sub>{previousAction.baseTo}</sub>
                        </>
                    break;
                case ("Operation"):
                    btnName =
                        <>
                            {previousAction.firstOperand}
                            <sub>{previousAction.firstOperandBase}</sub>
                            {" " + previousAction.operation + " "}
                            {previousAction.secondOperand}
                            <sub>{previousAction.secondOperandBase}</sub>
                            {" = "}
                            {previousAction.result}
                            <sub>{previousAction.resultBase}</sub>
                        </>
                    break;
                case ("Coding"):
                    btnName = "исходное число " + previousAction.number + ", прямой код: " + previousAction.directCode + ", обратный код: " + previousAction.reverseCode + ", дополнительный код: " + previousAction.additionalCode;
                    break;
                case ("OneMatrixOperation"):
                    switch (previousAction.button) {
                        case 1:
                            btnName = "подсчёт определителя матрицы, результат: " + previousAction.determinant;
                            break;
                        case 2:
                            btnName = "возведение матрицы в степень с показателем: " + previousAction.matrixPow;
                            break;
                        case 3:
                            btnName = "умножение матрицы на: " + previousAction.matrixFactor;
                            break;
                        default:
                            break
                    }
                    break;
                case ("TwoMatrixOperation"):
                    switch (previousAction.button) {
                        case 1:
                            btnName = "сложение матриц с размерами: " + previousAction.matrixHeight1 + "×" + previousAction.matrixWidth1 + " и " + previousAction.matrixHeight2 + "×" + previousAction.matrixWidth2;
                            break;
                        case 2:
                            btnName = "вычитание матриц с размерами: " + previousAction.matrixHeight1 + "×" + previousAction.matrixWidth1 + " и " + previousAction.matrixHeight2 + "×" + previousAction.matrixWidth2;
                            break;
                        case 3:
                            btnName = "умножение матриц с размерами: " + previousAction.matrixHeight1 + "×" + previousAction.matrixWidth1 + " и " + previousAction.matrixHeight2 + "×" + previousAction.matrixWidth2;
                            break;
                        default:
                            break
                    }
                    break;
                case ("LogicOperation"):
                    btnName = "выражение: " + previousAction.normalizeInput;
                    break;
                case ("FunctionOperation"):
                    switch (previousAction.button) {
                        case 1:
                            btnName = "Полином Жегалкин для выражения ";
                            break;
                        case 2:
                            btnName = "СКНФ для выражения ";
                            break;
                        case 3:
                            btnName = "СДНФ для выражения ";
                            break;
                        default:
                            break
                    }

                    btnName += previousAction.normalizeInput + " имеет вид: " + previousAction.result;
                    break;
                case ("NokNod"):
                    btnName = "Для набора чисел: {";
                    for (let i = 0; i < previousAction.numbers.length; ++i) {
                        if (i > 0) {
                            btnName += ", ";
                        }
                        btnName += previousAction.numbers[i];
                    }
                    btnName += "} выходит, что НОК: " + previousAction.NOK + ", НОД: " + previousAction.NOD;
                    break;
                case ("Pow"):
                    previousAction.input = previousAction.input.split('`').join(String.fromCharCode(1)).split('~').join(String.fromCharCode(2));
                    previousAction.normalizeInput = previousAction.normalizeInput.split('`').join(String.fromCharCode(1)).split('~').join(String.fromCharCode(2));
                    btnName += previousAction.normalizeInput.split(String.fromCharCode(1)).join('(').split(String.fromCharCode(2)).join(')') + " = " + previousAction.result;
                    break;
                case ("Polish"):
                    btnName += previousAction.input + " Польская запись: " + previousAction.result.toString().replaceAll("_", " ");
                    break;
                default:
                    break;
            }

            arr.unshift([btnName, previousAction, i]);
        }

        if (arr.length > 0) {
            return (
                <div className="my-border">
                    {title}
                    <div className="previousActions" >
                        {
                            arr.map((i) => {
                                return (
                                    <div key={i[2]}>
                                        <center>
                                            <button className="previousAction in-div mw90" type="button" onClick={() => this.props.handleClick(i[1])}><span className="max-width-100 no-overflow-y">{'\u00A0'} {i[0]} {'\u00A0'}</span></button>
                                        </center>
                                    </div>
                                )
                            })
                        }
                    </div >
                </div>
            );
        }

        return (
            <></>
        )
    }
}

export default PreviousActions;