import React from "react";
import Footer from "../../components/GeneralItems/Footer";
import Header from "../../components/GeneralItems/Header";
import HeadData from "../../components/HeadData/HeadData";

class PageInstruments extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <HeadData
                    title="Инструменты – MathInstruments"
                    url="https://MathInstruments.ru/Instruments"
                    keys="MathInstruments, Main, MaIn, математические калькуляторы, калькуляторы, математические инструменты, Инструменты, инструменты, навигация, поиск, информация, инфо"
                    description="Быстрая навигация по сайту со ссылками на страницы разделёнными по смысловым группам."
                />

                <Header updater={() => this.forceUpdate()} />
                
                <span id="ECS"></span>

                <main className="content">
                    <div className="check"></div>
                    <center>
                        <div className="instruments">
                            <h1 className="titels">Системы счисления:</h1>
                            <br />

                            <div className="card">
                                <h1>Перевод целых чисел из одной системы счисления в другую.</h1>
                                <p>На данной странице представлен функционал, позволяющий пользователям переводить целые и вещественные числа из одной системы счисления в другую с заданной точностью.</p>
                                <a href="/NumberTranslator">Приступить.</a>
                            </div>
                            <div className="card">
                                <h1>Операции над числами в различных системах счисления.</h1>
                                <p>На данной странице мы предлагаем пользователям функционал, позволяющий выполнять арифметические операции над числами в различных системах счисления и получать результат с заданной точностью.</p>
                                <a href="/NumberOperations">Перейти.</a>
                            </div>
                            <div className="card">
                                <h1>Прямой, обратный и дополнительный код числа.</h1>
                                <p>На данной странице пользователи могут производить перевод чисел в прямой, обратный и дополнительный коды.</p>
                                <a id="matrix" href="/NumberCoding">Ознакомиться.</a>
                            </div>

                            <br />
                            <h1 className="titels">Матрицы:</h1>
                            <br />

                            <div className="card">
                                <h1>Операции с одной матрицей.</h1>
                                <p>На данной странице представлен функционал, позволяющий пользователям легко считать определитель матрицы, находить её степень, умножать на число, а также производить ряд дополнительных преобразований над вещественной матрицей.</p>
                                <a href="/OneMatrixOperations">Попробовать.</a>
                            </div>
                            <div className="card">
                                <h1>Операции с двумя матрицами.</h1>
                                <p>На данной странице мы предлагаем пользователям функционал, позволяющий быстро умножать, вычитать и складывать вещественные матрицы.</p>
                                <a id="DM" href="/twoMatrixOperations">Начать.</a>
                            </div>

                            <br />
                            <h1 className="titels">Дискретная математика:</h1>
                            <br />

                            <div className="card">
                                <h1>Алгебра логики.</h1>
                                <p>На данной странице доступен функционал, позволяющий легко вычислять различные логические выражения, а также состовлять таблицы истинности.</p>
                                <a href="/AlgebraOfLogic">Изучить.</a>
                            </div>
                            <div className="card">
                                <h1>Полином Жегалкина, СКНФ и СДНФ.</h1>
                                <p>На данной странице мы предлагаем пользователям функционал, позволяющий по заданному логическому выражнию быстро вычислять полином Жегалкина, СКНФ и СДНФ.</p>
                                <a id="Additional" href="/FunctionOfLogic">Перейти.</a>
                            </div>

                            <br />
                            <h1 className="titels">Вспомогательные функции:</h1>
                            <br />

                            <div className="card">
                                <h1>Поиск наибольшего общего делителя и наименьшего общего кратного.</h1>
                                <p>На данной странице пользователи могут без затруднений получить наибольший общий делитель и наименьшее общее кратное набора чисел произвольной длины.</p>
                                <a href="/NokNod">Ознакомиться.</a>
                            </div>

                            <div className="card">
                                <h1>Подсчёт числовых выражений с числами в различных степенях.</h1>
                                <p>На данной странице мы предлагаем пользователям функционал, позволяющий легко получить значение выражения, состоящего из чисел в различных степенях.</p>
                                <a href="/Pow">Начать.</a>
                            </div>

                            <div className="card">
                                <h1>Нахождение польской нотации.</h1>
                                <p>На данной странице доступен функционал, позволяющий без труда по заданному выражению получить его польскую нотацию.</p>
                                <a href="/PolishNotation">Приступить.</a>
                            </div>
                        </div>
                    </center>
                </main>

                <Footer />
            </div >
        );
    }
}

export default PageInstruments;