import React from "react";
import Footer from "../../components/GeneralItems/Footer";
import Header from "../../components/GeneralItems/Header";
import HomeBackground from "../../components/HomeAnimation/HomeBackground";
import HeadData from "../../components/HeadData/HeadData";
import { HashLink as Link } from "react-router-hash-link";

class PageHome extends React.Component {
  render() {
    return (
      <div className="home">
        <div className="wrapper">
          <HeadData
            title="Домашняя страница – MathInstruments"
            url="https://MathInstruments.ru"
            keys="MathInstruments, Main, MaIn, математические калькуляторы, калькуляторы, математические инструменты, Домашняя страница, дом, Главная, главная, информация, инфо"
            description="С помощью MathInstruments вам станет доступен широкий выбор инструментов для математических вычислений. Наша платформа поможет вам с операциями над числами в различных системах счисления, работой с матрицами, поиском коньюктивных и дизъюнктивных функций, алгеброй логики, а также ещё с рядом популярных проблем из области дискретной математики и информатики."
          />

          <Header
            updater={() => {
              this.forceUpdate();
            }}
          />

          <main className="home-content">
            <HomeBackground />

            <center>
              <div className="Text mr1pr">
                <center>
                  <h2>
                    Добро пожаловать сайт Math Instruments, где математика
                    становится легкой и интересной!
                  </h2>
                  <br />
                  <span className="discription">
                    У нас вы найдете широкий выбор инструментов для
                    математических вычислений. Наша платформа поможет вам с{" "}
                    <Link to="/Instruments#ECS">
                      операциями над числами в различных системах счисления,
                    </Link>{" "}
                    <Link to="/Instruments#matrix">работой с матрицами,</Link>{" "}
                    <Link to="/Instruments#DM">
                      поиском коньюктивных и дизъюнктивных функций, алгеброй
                      логики,
                    </Link>{" "}
                    <Link to="/Instruments#Additional">
                      а также ещё с рядом популярных проблем из области
                      дискретной математики и информатики.
                    </Link>{" "}
                    Мы предлагаем удобный интерфейс и мощные алгоритмы для
                    точных вычислений. Независимо от вашего уровня подготовки,
                    вы сможете найти здесь подходящие для себя инструменты. С
                    нашим сайтом вы сможете решать задачи из различных областей
                    математики, информатики, линейной алгебры, дискретной
                    математики и других. Мы постоянно обновляем наш функционал,
                    чтобы предоставить вам самые современные инструменты для
                    решения популярных задач. Наша команда профессионалов
                    гарантирует качественную поддержку и быстрое решение любых
                    вопросов. Начните использовать наш сайт прямо сейчас и
                    убедитесь в его удобстве и эффективности. Мы стремимся
                    сделать математику доступной и понятной для всех!
                  </span>

                  <div className="text_container">
                    <a href="/Instruments">
                      <h2 className="lowerInstrumentLink">
                        Перейти к инструментам?
                      </h2>
                      <h2 className="italicH2">Перейти к инструментам!</h2>
                    </a>
                  </div>
                </center>
              </div>
            </center>
          </main>

          <Footer />
        </div>
      </div>
    );
  }
}

export default PageHome;
