import React from "react";


class ListOfNumbers extends React.Component {
    isNumber(str) {
        if (str.length === 0) {
            return false;
        }

        if (str[0] === '+') {
            if (str.length === 1) {
                return false;
            }
            str = str.slice(1);
        }

        let digits = "01234567890";

        let flag = true;

        for (let i = 0; i < str.length; ++i) {
            if (digits.indexOf(str[i]) === -1) {
                flag = false;
            }
        }

        return flag;
    }

    render() {
        if (!this.props.numbersCount || !this.isNumber(this.props.numbersCount) || this.props.numbersCount <= 0) {
            return (<></>);
        }

        let args = [];

        for (let i = 0; i < Math.min(this.props.numbersCount, 50); ++i) {
            if (i < this.props.numbers.length) {
                args.push([i, this.props.numbers[i]]);
            } else {
                args.push([i, ""]);
            }
        }

        return (
            <div className="width100">
                <h3>Числа:</h3>
                {
                    args.map((i) => {
                        return (
                            <div key={i[0]}>
                                <h3 className="inline mid">Число {i[0] + 1}:</h3>
                                <input className="form_control em10 inline" autoComplete="off" type="text" id={i[0]} placeholder="Введите число" value={i[1]} onChange={() => this.props.onChange()} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default ListOfNumbers;