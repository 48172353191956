import React from "react";

class NokNodResult extends React.Component {
    render() {
        if (this.props.NOK && this.props.NOD) {
            return (
                <div className="my-border">
                    <h2>НОК:</h2>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.NOK}</output>
                    <br />
                    <h2>НОД:</h2>
                    <output className="form_control output-style" onChange={() => { }}>{this.props.NOD}</output>
                </div >
            )
        }

        return (
            <></>
        )
    }
}

export default NokNodResult;