let DarkSelect = {
    singleValue: provided => ({
        ...provided,
        color: 'antiquewhite',
        backgroundColor: "#303841",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#303841",
        border: "solid 1px antiquewhite",
        borderRadius: "5px",
        color: "antiquewhite",
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: "antiquewhite",
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: "antiquewhite",
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: "#303841",
        color: "antiquewhite",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
    }),
}

export default DarkSelect;