import { Helmet } from "react-helmet"
import React from "react";

class HeadData extends React.Component {
    render() {
        return (
            <Helmet
                title={this.props.title}
                meta={[{ "charset": "utf-8" },
                { "name": "viewport", "content": "width=device-width, user-scalable=no" },
                { "property": "og:type", "content": "website" },
                { "name": "bingbot", "content": "nocache" },
                { "property": "og:site_name", "content": "MathInstruments" },
                { "property": "og:locale", "content": "ru_RU" },
                { "name": "keywords", "content": this.props.keys },
                { "name": "description", "content": this.props.description },
                { "property": "og:url", "content": this.props.url },
                { "property": "og:title", "content": this.props.title },
                { "property": "og:description", "content": this.props.description }
                ]}
                link={[
                    { "rel": "icon", "type": "image/svg+xml", "href": "/images/m-high-resolution-logo-transparent.svg" },
                    { "rel": "icon", "href": "/favicon.ico", "type": "image/x-icon" }
                ]}
            />)
    }
}

export default HeadData;