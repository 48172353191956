import React from "react";

class LogicResult extends React.Component {
    render() {
        if (!this.props.result) {
            return (
                <></>
            );
        }

        let ik = 0;

        return (
            <div className="my-border">
                <h3>Результат вычисления логического выражения:</h3>
                <output className="form_control output-style" onChange={() => { }}>{this.props.result}</output>

                <br />
                <h3>Таблица истинности:</h3>

                <div className="scroll-table">
                    <table className="true-false-table">
                        <tbody className="true-false-table">
                            {
                                this.props.table.map((i) => {
                                    let jk = 0;
                                    return (
                                        <tr key={ik++}>
                                            {
                                                i.map((j) => {
                                                    return (<td key={jk++}>
                                                        {j}
                                                    </td>);
                                                })
                                            }
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default LogicResult;